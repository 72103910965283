@import '../../../../assets/styles/_default/variables';
@import '../../../../assets/styles/_mixins/breakpoint';

.page {
    display: flex;
    justify-content: stretch;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    display: flex;
    
    @media screen and (max-width: 1199px) {
      height: auto;
      flex-direction: column;
    }

    &_main_footer_wrapper {
        width: calc(100vw - 50px);
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: stretch;
        height: 100vh;
        scroll-behavior: smooth;
        scrollbar-width: thin;          /* "auto" или "thin"  */
        scrollbar-color: gray lightgray;   /* плашка скролла и дорожка */
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-track {
        background: lightgray;        /* цвет дорожки */
      }
      &::-webkit-scrollbar-thumb {
        background-color: gray;    /* цвет плашки */
        border-radius: 20px;       /* закругления плашки */
        border: 3px solid lightgray;  /* padding вокруг плашки */
      }


      @include breakpoint($tablet__max) {
        &::-webkit-scrollbar {
          width: 0;
        }
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
      }

      @media screen and (max-width: 1199px) {
        width: 100vw;
        height: auto;
      }
      @media screen and (max-width: 600px) {
        height: auto;
      }
    }

    .free-place{
      height: 100vh;
      // position: absolute;
      width: 100vw;
      box-sizing: border-box;
    
      &__header{
        position: relative;
        z-index: 999;
        height: 70px;
      }
    }
}