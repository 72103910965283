@import 'animations';

%fill-background-from-top,
%fill-background-from-bottom {
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  animation: fill_vertical 3s ease-in-out 1 normal both;
  z-index: 3;
}

%fill-background-from-bottom {
  top: 0;
}

.mainTitle {
  position: absolute;
  opacity: 0;
}

.mainWrapper {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: relative;

  .swiper-slide{
    position: unset;
    padding: 0;
    @media screen and (max-width: 1200px) {
      height: 281px;
    }
  }

  &_col {
    height: 100%;
    &_first {
      width: 13%;
      //display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      position: relative;

      &_menu {
        height: 67%;
        background-color: #5A5863;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          margin-top: 60px;
          width: 90%;
          li.menu_item {
            margin-top: 23px;
            a {
              font-family: 'Roboto', sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 22px;
              line-height: 24px;
              letter-spacing: -0.03em;
              text-transform: uppercase;
              color: #FFFFFF;
              &.store-item, &.contacts-item {
                color: gray;
              }
              @media screen and (max-height: 780px) {
                font-size: 16px;
              }
            }
            @media screen and (max-height: 780px) {
              margin-top: 10px;
            }
          }
        }

        &_line_horizontal_bottom {
          background-color: red;
          position: absolute;
          width: 0px;
          height: 1px;
          bottom: 0;
          left: 0;
          animation: line_horizontal 3s ease-in-out 1 normal both;
          z-index: 3;
        }

        &_line_horizontal_top {
          background-color: red;
          position: absolute;
          width: 0px;
          height: 1px;
          top: 0;
          right: 0;
          animation: line_horizontal 3s ease-in-out 1 normal both;
          z-index: 3;
        }

        &_animated_fill-from-top {
          @extend %fill-background-from-top;
        }
      }

      &_img {
        height: 33%;
        background: radial-gradient(54.27% 54.27% at 50% 50%, #afb3b7  0%, #727077 100%);;
        // background: url(../../../assets/img/mainScreen/chickenFirstColumn.jpg) center/cover no-repeat;
        .chicken{
          position: absolute;
          background: url(../../../assets/img/mainScreen/chicken-new1.webp);
          background-size: 100px;
          background-position: center 15px;
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          @media screen and (max-height: 950px) {
            background-size: 100px;
            height: 40%;
            background-position: center center;
          }
          @media screen and (max-height: 800px) {
            background-size: 90px;
            height: 40%;
            background-position: center center;
          }
          @media screen and (max-height: 800px) {
            background-size: 90px;
            height: 40%;
            background-position: center center;
          }
          @media screen and (max-width: 740px) {
            background-size: 80px;
            height: 33%;
            background-position: center 50px;
          }
          @media screen and (max-width: 1200px) {
            background-size: 125px;
            height: 36%;
            background-position: center center;
          }
          @media screen and (min-width: 1400px) {
            background-size: 165px;
            height: 36%;
            background-position: center center;
          }
        }
        .marker {
          position: relative;
          left: 11px;
          top: 30px;
          display: table;
          // width: max-content;
          padding: 0 10px;
          height: 27px;
          background: #971811;
          opacity: 0.4;
          z-index: 2;
          @media screen and (max-width: 1200px) {
            left: 18px;
            top: 12px;
          }

          p {
            display: block;
            text-transform: uppercase;
            font-family: 'Roboto', 'Arial', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 27px;
            color: #FFFFFF;
            text-align: center;
            margin: 0;
            padding: 0;
          }
        }

        @media screen and (max-width: 1200px) {
          height: 191px;
        }
        &_animated_fill-from-top2 {
          @extend %fill-background-from-top;
          animation: fill_vertical2 3s ease-in-out 1 normal both;
        }
      }

      &_right_vertical_line {
        background-color: red;
        position: absolute;
        width: 1px;
        height: 0px;
        top: 0;
        right: 0;
        animation: line_vertical 3s ease-in-out 1 normal both;
        z-index: 3;
      }

      &_left_vertical_line {
        background-color: red;
        position: absolute;
        width: 1px;
        height: 0px;
        top: 0;
        left: 0;
        animation: left_line_vertical 3s ease-in-out 1 normal both;
        z-index: 3;
      }
    }

    &_second {
      width: 16%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      box-sizing: border-box;
      @media screen and (max-width: 1200px) {
        width: 50%;
      }
      &_online {
        height: 67%;
        background-color: gray;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        .marker {
          position: absolute;
          left: 19px;
          top: 23px;
          width: 131px;
          height: 27px;
          background: #971811;
          opacity: 0.4;
          z-index: 2;
          @media screen and (max-width: 1200px) {
            left: 18px;
            top: 12px;
          }
          p {
            display: block;
            text-transform: uppercase;
            font-family: 'Roboto', 'Arial', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 27px;
            color: #FFFFFF;
            text-align: center;
            margin: 0;
            padding: 0;
          }
        }
        &_animation {
          height: 50%;
          position: relative;
          background-position:  center;
          background-size: cover;
          background-repeat: no-repeat;
          @media screen and (max-width: 1200px) {
            height: 302px;
          }
        }

        &_line_horizontal_bottom {
          background-color: red;
          position: absolute;
          width: 0px;
          height: 1px;
          bottom: 0;
          left: 0;
          animation: line_horizontal 3s ease-in-out 1 normal both;
          z-index: 3;
        }

        &_line_horizontal_top {
          background-color: red;
          position: absolute;
          width: 0px;
          height: 1px;
          top: 0;
          right: 0;
          animation: line_horizontal 3s ease-in-out 1 normal both;
          z-index: 3;
        }

        &_animated_fill-from-bottom {
          @extend %fill-background-from-bottom;
        }
      }

      &_meal-for-brain {
        width: 100%;
        height: 33%;
        background-color: gray;
        position: relative;
        display: flex;
        box-sizing: border-box;
        .marker {
          position: absolute;
          left: 30px;
          top: 30px;
          // width: max-content;
          padding: 0 10px;
          height: 27px;
          background: #971811;
          opacity: 0.4;
          z-index: 2;
          @media screen and (max-width: 1200px) {
            left: 18px;
            top: 12px;
          }
          p {
            display: block;
            text-transform: uppercase;
            font-family: 'Roboto', 'Arial', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 27px;
            color: #FFFFFF;
            text-align: center;
            margin: 0;
            padding: 0;
          }
        }
        .swiperContainer{
          .swiper-slide{
            height: 281px;
          }
        }
        &_animated_fill-from-bottom2 {
          @extend %fill-background-from-bottom;
          animation: fill_vertical2 3s ease-in-out 1 normal both;
        }
      }
    }

    &_third {
      width: 27%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      position: relative;
      @media screen and (max-width: 1200px) {
        width: 50%;
      }
      &_recipes {
        height: 67%;
        background-color: gray;
        position: relative;
        background: url(../../../assets/img/mainScreen/recipesImageNew.webp) center/cover no-repeat;
        cursor: pointer;
        padding: 15px 30px 10px;
        box-sizing: border-box;
        @media screen and (max-width: 1200px) {
          height: 280px;
        }
        .router_wrapper {
          width: 100%;
          height: 100%;
        }
        .marker {
          position: absolute;
          left: 30px;
          top: 23px;
          // width: max-content;
          padding: 0 10px;
          height: 27px;
          background: #971811;
          opacity: 0.4;
          @media screen and (max-width: 1200px) {
            left: 12px;
            top: 12px;
            // opacity: 1;
          }
          z-index: 2;
          &.marker-recipes {
            background-color: #292F35;
          }

          p {
            display: block;
            text-transform: uppercase;
            font-family: 'Roboto', 'Arial', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 27px;
            color: rgba(255, 255, 255, 0.45);
            text-align: center;
            margin: 0;
            padding: 0;
          }
        }
        &_line_horizontal_bottom {
          background-color: red;
          position: absolute;
          width: 0px;
          height: 1px;
          bottom: 0;
          left: 0;
          animation: line_horizontal 3s ease-in-out 1 normal both;
          z-index: 3;
        }

        &_line_horizontal_top {
          background-color: red;
          position: absolute;
          width: 0px;
          height: 1px;
          top: 0;
          right: 0;
          animation: line_horizontal 3s ease-in-out 1 normal both;
          z-index: 3;
        }

        &_animated_fill-from-top {
          @extend %fill-background-from-top;
          animation: fill_vertical-to-middle-from-top 3s ease-in-out 1 normal both;
        }
      }

      &_right_vertical_line {
        background-color: red;
        position: absolute;
        width: 1px;
        height: 0px;
        top: 0;
        right: 0;
        animation: line_vertical 3s ease-in-out 1 normal both;
        z-index: 3;
      }

      &_left_vertical_line {
        background-color: red;
        position: absolute;
        width: 1px;
        height: 0px;
        top: 0;
        left: 0;
        animation: left_line_vertical 3s ease-in-out 1 normal both;
        z-index: 3;
      }

      &_news {
        height: 33%;
        width: 100%;
        background-color: gray;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        box-sizing: border-box;
        .marker {
          position: absolute;
          left: 30px;
          top: 30px;
          // width: max-content;
          padding: 0 10px;
          height: 27px;
          background: #971811;
          opacity: 0.4;
          z-index: 2;
          transition: opacity 0.7s;
          @media screen and (max-width: 1200px) {
            // opacity: 1;
            width: 85px;
            height: 41px;
            font-size: 13px;
            left: 12px;
            top: 7px;
          }
          &.marker-news {
            background-color: #292F35;
          }
          p {
            display: block;
            text-transform: uppercase;
            font-family: 'Roboto', 'Arial', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 27px;
            color: rgba(255, 255, 255, 0.45);
            text-align: center;
            margin: 0;
            padding: 0;
            @media screen and (max-width: 1200px) {
              line-height: 100%;
              padding-top: 8px;
              text-align: left;
            }
          }
          a {
            display: block;
            text-transform: uppercase;
            font-family: 'Roboto', 'Arial', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 27px;
            color: rgba(255, 255, 255, 0.45);
            text-align: center;
            margin: 0;
            padding: 0;
            @media screen and (max-width: 1200px) {
              line-height: 100%;
              padding-top: 8px;
              text-align: left;
            }
          }
          &:hover {
            opacity: 1;
          }

        }
        &_animated_fill-from-top {
          @extend %fill-background-from-top;
          animation: fill_vertical2 3s ease-in-out 1 normal both;
        }
      }
    }

    &_fourth {
      width: 44%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      @media screen and (max-width: 1200px) {
        width: 100%;
      }

      &_big-brand {
        height: 67%;
        position: relative;
        background: #E1251B url(../../../assets/img/mainScreen/logo-new.webp) center/100% no-repeat;
        padding: 15px 20px 10px;
        box-sizing: border-box;
        cursor: pointer;
        @media screen and (max-width: 1200px) {
          height: 107px;
          background-size: 130px;
          background-position: center 15px;
        }
        .marker {
          position: absolute;
          left: 30px;
          top: 23px;
          // width: max-content;
          padding: 0 10px;
          height: 27px;
          background: #971811;
          opacity: 0.4;
          z-index: 2;
          p {
            display: block;
            text-transform: uppercase;
            font-family: 'Roboto', 'Arial', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 27px;
            color: #FFFFFF;
            text-align: center;
            margin: 0;
            padding: 0;
          }
        }

        &_line_horizontal_bottom {
          background-color: red;
          position: absolute;
          width: 0px;
          height: 1px;
          bottom: 0;
          left: 0;
          animation: line_horizontal 3s ease-in-out 1 normal both;
          z-index: 3;
        }

        &_line_horizontal_top {
          background-color: red;
          position: absolute;
          width: 0px;
          height: 1px;
          top: 0;
          right: 0;
          animation: line_horizontal 3s ease-in-out 1 normal both;
          z-index: 3;
        }

        &_animated_fill-from-bottom {
          @extend %fill-background-from-bottom;
          animation: fill_vertical-to-middle 3s ease-in-out 1 normal both;
        }
      }

      &_brand-range {
        height: 33%;
        background-color: gray;
        position: relative;
        display: block;
        @media screen and (max-width: 1200px) {
          height: 116px;
        }
        &_slider {
          display: flex;
          height: 100%;
          width: 100%;
          justify-content: space-between;
          position: relative;
          .slider-item {
            width: 33%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            cursor: pointer;
            &:nth-child(1) {
              background-color: #F1F1F1;
              width: 34%;
              .master_cover {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: white url(../../../assets/img/mainScreen/master.svg) center center no-repeat;
                @media screen and (max-width: 1200px) {
                  background-size: 80px;
                }
                &:hover {
                  opacity: 0;
                }


              }
            }
            &:nth-child(2) {
              background-color: #F1F1F1;
              .ready-cover {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: white url(../../../assets/img/mainScreen/ready.svg) center center no-repeat;
                -webkit-box-shadow: 5px 0px 6px -5px rgba(34, 60, 80, 0.2) inset;
                -moz-box-shadow: 5px 0px 6px -5px rgba(34, 60, 80, 0.2) inset;
                box-shadow: 5px 0px 6px -5px rgba(34, 60, 80, 0.2) inset;
                @media screen and (max-width: 1200px) {
                  background-size: 80px;
                  box-shadow: none;
                }
                &:hover {
                  opacity: 0;
                }
              }

            }
            &:nth-child(3) {
              background-color: #F1F1F1;
              .sausages-cover {
                position: absolute;
                left: -1px;
                top: 0;
                width: 101%;
                height: 100%;
                z-index: 1;
                background: white url(../../../assets/img/mainScreen/sausage.png) center center no-repeat;
                background-size: 50%;
                -webkit-box-shadow: 5px 0px 6px -5px rgba(34, 60, 80, 0.2) inset;
                -moz-box-shadow: 5px 0px 6px -5px rgba(34, 60, 80, 0.2) inset;
                box-shadow: 5px 0px 6px -5px rgba(34, 60, 80, 0.2) inset;
                @media screen and (max-width: 1200px) {
                  box-shadow: none;
                  background-size: 80px;
                }
                &:hover {
                  opacity: 0;
                }
              }
            }
          }

        }

        &_animated_fill-from-bottom2 {
          @extend %fill-background-from-bottom;
          animation: fill_vertical2 3s ease-in-out 1 normal both;
        }
      }
    }
  }

}

.land-play-icon{
  position: absolute;
  display: block;
  bottom: 50%;
  left: 50%;
  transform: scale(1) translate(-50%, 50%);
  width: 58px;
  height: 58px;
  background: url('../../../assets/img/petruhaOnline/play-icon.png') center center no-repeat;
  // pointer-events: none;

  &:hover {
    animation: box-shadow-hover 2s ease infinite;
    position: absolute;
    transition: animation ease 2s;
    border-radius: 50px;
  }
}

.newsSwiper{
  .swiper-slide{
    @media screen and (max-width: 1200px) {
      height: 302px;
    }
  }
}

.swiperContainer2{
  .mealForBrainSwiper{
    .swiper-slide{
      @media screen and (max-width: 1200px) {
        height: 190px;
      }
    }
  }
}



.mainWrapper_col_fourth_brand-range{
  .mealForBrainSwiper{
    .swiper-slide{
      @media screen and (max-width: 1200px) {
        height: 140px;
      }
    }
  }
}
