@import '../../../assets/styles/_default/variables';
@import '../../../assets/styles/_mixins/breakpoint';

.petruhaBrandWrapper {
  width: 100%;
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  background-color: #4c5363;

  @include breakpoint($tabletS__max) {
    height: auto;
    width: 70%;
    margin: 0 auto;
  }

  @include breakpoint($mobile__max) {
    width: 100%;
  }

  .logo {
    position: absolute;
    top: 105px;
    right: 40px;
    cursor: pointer;
    width: 178px;
    height: 90px;
    background: url('../../../assets/img/petruhaBrandContent/logo.svg') center/cover no-repeat;
    transition: opacity .5s;
    &:hover {
      opacity: .5;
    }
    @media screen and (max-height: 900px) {
      top: 90px;
      width: 160px;
      height: 80px;
    }
    @include breakpoint($tabletS__max) {
      top: 20px;
      right: auto;
      left: auto;
      width: 144px;
      height: 72px;
    }
    @include breakpoint($mobile__max) {
      left: 19px;
    }
  }
  &.petruhaBrandWrapper_transparent {
    background-color: transparent;
  }
  .brandsNavigation {
    display: flex;
    justify-content: flex-start;
    width: 973px;
    align-items: center;
    top: 44px;
    right: 172px;
    height: 26px;
    position: absolute;
    @include breakpoint($tabletS__max) {
      display: none;
    }
    .brands-nav-item {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      margin-right: 40px;
      color: #ffffff;
      cursor: pointer;
      &:nth-child(1) {
        padding-bottom: 3px;
        border-bottom: 1px solid white;
      }
    }
  }
  &_header {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 100%;
    color: #FFFFFF;
    margin-top: 12vh;
    margin-left: 0;
    width: 1064px;
    word-wrap: normal;
    @include breakpoint($laptop__max) {
      font-size: 48px;
    }

    @include breakpoint($tabletS__max) {
      margin-top: 123px;
      font-weight: normal;
      font-size: 22px;
      line-height: 109%;
      margin-left: 0;
    }
    @include breakpoint($mobile__max) {
      width: 190px;
    }

    @media screen and (max-width: 1200px) {
      margin-top: 17px;
      display: block;
      margin-left: 155px;
      width: 190px;
      padding: 10px 25px;
    }
  }

  &_content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: stretch;
    height: 740px;
    padding-top: 50px;
    overflow: hidden;
    @include breakpoint($tabletS__max) {
      padding-top: 17px;
      flex-direction: column;
      height: auto;
      overflow-y: visible;
      margin-top: 0;
    }
    .first-col {
      width: 23%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      cursor: pointer;
      position: relative;
      padding-left: 60px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0px 40px 30px 60px;
      @include breakpoint($tabletS__max) {
        width: 100%;
        height: 417px;
        padding: 19px;
        // padding: 15px 19px 15px;
      }
      .play-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        height: auto;
        .play-icon {
          width: 77px;
          height: 77px;
          background: url('../../../assets/img/petruhaBrandContent/playIcon.png') center center no-repeat;
          margin-bottom: 30px;
          position: relative;
          bottom: 20px;
          left: 40px;
          transform: scale(1) translate(-50%, 50%);
          // pointer-events: none;
          &:hover {
            animation: box-shadow-hover 2s ease infinite;
            // transition: all ease 2s;
            border-radius: 50px;
          }
        }
        .play-description {
          width: 100%;
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 144%;
          color: #FFFFFF;
          pointer-events: none;
          word-wrap: break-word;
          @include breakpoint($tabletS__max) {
            font-size: 16px;
          }
        }
      }
    }
    .second-col {
      width: 44%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      @include breakpoint($tabletS__max) {
        width: 100%;
      }
      .top {
        height: 37%;
        background-color: #ffffff;
        padding: 37px 100px 23px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 1800px) {
          padding-top: 15px;
        }
        @include breakpoint($tabletS__max) {
          // position: relative;
          padding: 0;
          display: unset;
        }
        .descr {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 130%;
          color: #000000;
          @media screen and (max-width: 1800px) {
            font-size: 16px;
          }
          @media screen and (max-height: 825px) {
            font-size: 13px;
          }
          @include breakpoint($tabletS__max) {
            height: auto;
            width: 100%;
            padding: 20px;
            padding-bottom: 15px;
            font-size: 11px;
            line-height: 130%;
            font-weight: 400;
          }
        }
        .small-header {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 32px;
          line-height: 120%;
          @media screen and (max-width: 1440px) {
            font-size: 22px;
          }
          @media screen and (max-height: 825px) {
            font-size: 16px;
          }
          @media screen and (max-height: 600px) {
            font-size: 14px;
          }
          color: #000000;
          @include breakpoint($tabletS__max) {
            font-size: 16px;
            font-weight: 400;
            padding: 0px 55px 45px 20px;
          }
        }
      }
      .bottom {
        height: 63%;
        width: 100%;
        position: relative;
        @include breakpoint($tabletS__max) {
          display: unset;
        }
        .flag {
          position: absolute;
          width: 323px;
          height: 77px;
          left: 0px;
          bottom: 0;
          background: #E1251B;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          color: #FFFFFF;
          cursor: pointer;
          @include breakpoint($tabletS__max) {
            width: 100%;
          }
        }
        .swiper-container {
          width: 100%;
          height: 100%;
          .swiper-pagination-bullets {
            text-align: left;
            padding-left: 60px;
            .swiper-pagination-bullet {
              background-color: red;
              opacity: 1;
              &.swiper-pagination-bullet-active {
                background-color: white;
              }
            }
          }
        }
        .cover {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center center;
          cursor: pointer;
        }
      }
    }
    .third-col {
      width: 33%;
      height: 100%;
      background-color: #efefef;
      position: relative;
      flex-grow: 0;
      flex-shrink: 0;
      @include breakpoint($tabletS__max) {
        min-height: 420px;
        width: 100%;
        height: 87vw;
        // padding: 15px 19px 15px;
      }
      @include breakpoint($mobileS__max) {
        height: 90vw;
      }
      @include breakpoint($mobile__max) {
        height: 130vw;
        // min-height: 296px;
      }
      .bottom {
        height: 100%;
        width: 100%;
        position: relative;
        @include breakpoint($tabletS__max) {
          height: auto;
          position: absolute;
          bottom: 0;
        }
        .flag {
          position: absolute;
          width: 100%;
          height: 77px;
          left: 0px;
          bottom: 0px;
          color: #ffffff;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          background: #E1251B;
          @include breakpoint($tabletS__max) {
            top: auto;
            bottom: 0;
          }

          @include breakpoint($mobileS__max) {
            width: 100%;
          }
        }
      }
      .image {
        position: absolute;
        width: 140%;
        height: 140%;
        top: -80px;
        left: -35px;
        background-position: 27px 55px;
        background-repeat: no-repeat;
        background-size: 628px;
        z-index: 1;
        @include breakpoint($tabletS__max) {
          background-size: contain;
          background-position: center 50px;
          left: 0;
          width: 100%;
          z-index: 0;
        }
      }
    }
  }
  .videoPopup {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #000000bb;
    z-index: 15;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}



