@import "../../../assets/styles/_default/variables";
@import "../../../assets/styles/_mixins/breakpoint";

.petruhaMasterWrapper {
  width: 100%;
  height: calc(100vh - 90px);
  // overflow-y: scroll;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: transparent;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
  }

  @include breakpoint($tabletS__max) {
    height: auto;
    width: 70%;
    margin: 0 auto;
  }

  @include breakpoint($mobile__max) {
    width: 100%;
  }
  .logo {
    position: absolute;
    top: 114px;
    right: 40px;
    cursor: pointer;
    width: 171px;
    height: 110px;
    background: url("../../../assets/img/petruhaMaster/logo.svg") center/contain no-repeat;
    transition: opacity .5s;
    &:hover {
      opacity: .5;
    }

    @include breakpoint($tabletS__max) {
      top: 20px;
      right: auto;
      left: auto;
      width: 136px;
      height: 83px;
    }

    @include breakpoint($mobile__max) {
      left: 19px;
    }
  }
  &.petruhaMaterWrapper_transparent {
    background-color: transparent;
  }
  .brandsNavigation {
    display: flex;
    justify-content: space-between;
    width: 973px;
    align-items: center;
    top: 44px;
    right: 172px;
    height: 26px;
    position: absolute;

    @include breakpoint($tabletS__max) {
      display: none;
    }
    .brands-nav-item {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      margin-right: 40px;
      color: #ffffff;
      cursor: pointer;
      &:nth-child(2) {
        padding-bottom: 3px;
        border-bottom: 1px solid white;
      }
    }
  }
  &_header {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 100%;
    color: #ffffff;
    margin-top: 15vh;
    margin-left: 0px;
    width: 1064px;
    word-wrap: normal;

    @include breakpoint($laptop__max) {
      font-size: 48px;
    }

    @include breakpoint($tabletS__max) {
      margin-top: 123px;
      font-weight: normal;
      font-size: 22px;
      line-height: 109%;
      margin-left: 0;
    }

    @media screen and (max-width: 1200px) {
      margin-top: 17px;
      display: block;
      margin-left: 160px;
      width: 170px;
      padding: 20px 25px;
    }
  }

  &_content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 740px;
    padding-top: 65px;
    overflow: hidden;
    @include breakpoint($tabletS__max) {
      padding-top: 17px;
      flex-direction: column;
      height: auto;
      overflow-y: visible;
    }
    .first-col {
      width: 35%;
      height: 100%;
      background: linear-gradient(144.28deg, #f9aa35 15.62%, #b94830 131.93%);
      position: relative;
      flex-grow: 0;
      flex-shrink: 0;

      @include breakpoint($tabletS__max) {
        min-height: 420px;
        width: 100%;
        height: 75vw;
        // padding: 15px 19px 15px;
      }
      @include breakpoint($mobileS__max) {
        height: 90vw;
      }
      @include breakpoint($mobile__max) {
        height: 120vw;
        // min-height: 296px;
      }
      .bottom {
        height: 100%;
        width: 100%;
        position: relative;
        @include breakpoint($tabletS__max) {
          height: auto;
          position: absolute;
          bottom: 0;
        }
        .flag {
          position: absolute;
          width: 100%;
          height: 77px;
          left: 0px;
          bottom: 0px;
          background: #ffffff;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          color: #2a384c;
          @include breakpoint($tabletS__max) {
            top: auto;
            bottom: 0;
          }

          @include breakpoint($mobileS__max) {
            width: 100%;
          }
        }
      }
      .image {
        position: absolute;
        background-repeat: no-repeat;
        z-index: 0;
        width: 100%;
        right: 0;
        height: 115%;
        top: -60px;
        background-position: top center;
        background-size: auto;
        @include breakpoint($tabletS__max) {
          z-index: 1;
          background-size: contain;
          width: 118%;
          background-position: 95px 21px;
        }
        @include breakpoint($mobileS__max) {
          width: 125%;
          background-position: 75px 40px;
        }
        @include breakpoint($mobile__max) {
          width: 125%;
          background-position: 60px 40px;
        }
      }
    }
    .second-col {
      width: 38%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex-shrink: 1;
      @include breakpoint($tabletS__max) {
        width: 100%;
      }
      .top {
        height: 47%;
        background-color: #45494e;
        padding: 28px 52px 23px 28px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
        justify-content: center;
        min-height: 217px;
        max-height: 317px;
        overflow-y: hidden;
        @include breakpoint($tabletS__max) {
          height: auto;
          padding: 0;
          // padding-bottom: 25px;
          justify-content: unset;
          max-height: unset;
          min-height: unset;
        }
        .descr {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 130%;
          color: #ffffffd6;
          white-space: pre-line;
          @include breakpoint($tabletS__max) {
            height: auto;
            padding: 20px;
            padding-bottom: 35px;
            font-size: 14px;
            line-height: 130%;
            font-weight: 400;
          }
          @media screen and (max-width: 1435px) {
            font-size: 16px;
          }
        }
        .small-header {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 32px;
          line-height: 120%;
          color: #000000;
        }
      }
      .bottom {
        height: 53%;
        width: 100%;
        position: relative;
        @include breakpoint($tabletS__max) {
          height: auto;
          // min-height: 219px;
        }

        @include breakpoint($mobileS__max) {
          // min-height: 296px;
        }
        .flag {
          position: absolute;
          width: 323px;
          height: 77px;
          left: 0px;
          top: 0px;
          background: #ffffff;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          color: #2a384c;
          @include breakpoint($tabletS__max) {
            top: auto;
            bottom: 0;
          }

          @include breakpoint($mobileS__max) {
            width: 100%;
          }
        }
        .swiper-container {
          width: 100%;
          height: 100%;
          @include breakpoint($tabletS__max) {
            display: none;
          }
          .swiper-pagination-bullets {
            text-align: left;
            padding-left: 60px;
            .swiper-pagination-bullet {
              background-color: red;
              opacity: 1;
              &.swiper-pagination-bullet-active {
                background-color: white;
              }
            }
          }
        }
        .cover {
          cursor: pointer;
          width: 100%;
          height: 100%;
          top: 0;
          right: 0;
          background-position: center;
          background-size: cover;
          @include breakpoint($tabletS__max) {
            height: auto;
            min-height: 220px;
            background-size: cover;
          }
        }
      }
    }
    .third-col {
      width: 27%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0px 40px 30px 60px;
      flex-shrink: 1;
      @include breakpoint($tabletS__max) {
        width: 100%;
        height: 340px;
        padding: 19px;
      }
      .play-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        height: auto;
        .play-icon {
          width: 77px;
          height: 77px;
          background: url("../../../assets/img/petruhaBrandContent/playIcon.png") center center no-repeat;
          margin-bottom: 30px;
          position: relative;
          bottom: 20px;
          left: 40px;
          transform: scale(1) translate(-50%, 50%);
          // pointer-events: none;
          &:hover {
            animation: box-shadow-hover 2s ease infinite;
            // transition: all ease 2s;
            border-radius: 50px;
          }
        }
        .play-description {
          width: 100%;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 144%;
          color: #ffffff;
          pointer-events: none;
          word-wrap: break-word;
          @include breakpoint($tabletS__max) {
            font-size: 16px;
          }
        }
      }
    }
  }
  .videoPopup {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #000000bb;
    z-index: 15;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
