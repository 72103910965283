@import '../../../../assets/styles/_default/variables';
@import '../../../../assets/styles/_mixins/breakpoint';

.ListItems {
  width: 100%;
  position: absolute;
  top: 100%;
  z-index: 9;
  left: 0;
  // background-color: #4c5363;
  overflow: hidden;
  font-weight: normal;
  border: none;
  border-bottom: 2px solid #9497AA;
  padding-bottom: 0px;
  padding-top: 0px;
  &::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }

  .OptionItem {
    text-align: left;
    background-color: white;
    transition: background-color .3s ease, color .3s ease;
    border-bottom: 1px solid #9497AA;
    
    .select-option {
      color: gray;
      width: 100%;
      padding: 10px 0;
      min-height: 38px;
      font-size: 12px;
      cursor: pointer;
      text-align: left;
      border: none;
      background-color: white;
      // &:hover {
      //   background-color: #fffffa;
      // }

      &:disabled {
        cursor: not-allowed;
      }

      &:not(:disabled) {
        &:hover {
          @include breakpoint($tabletS) {
            color: red;
          }
        }

      }

      @include breakpoint($tabletS) {
        padding: 10px 0;
      }

      @include breakpoint($tabletS) {
        font-size: 14px;
      }
    }

    &:last-child {
      border: none;
    }
  }
}

.select {
  cursor: text;

  &-opened {
    .i-arrow {
      transform: translateY(-50%) rotate(180deg) !important;
    }
  }
}

.toggled {
  position: relative;

  .select-arrow {
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    transition: transform .3s ease;
    width: 10px;
    height: 10px;
    // border-style: solid;
    // border-width: .5em .5em 0 .5em;
    // border-color: #000 transparent transparent transparent;
    background: url(../../../../assets/img/recipes/selectArrow.svg) center center no-repeat;

    @include breakpoint($tabletS) {
      right: 0px;
      border-width: 0.9em 0.5em 0 0.5em;
    }
  }

  .input {
    input {
      text-transform: none;
      font-weight: normal;
      color: #ffffff;
      &::-webkit-input-placeholder {
        color: #ffffff;
      }
      &.recipes-select {
        background-color: transparent;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid #9497AA;
        height: 32px;
        width: 333px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 120%;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        box-sizing: border-box;
      }
    }

    .select {
      cursor: pointer;
      padding-right: 1.6em;
    }
  }
}

.disable {
  pointer-events: none;
}