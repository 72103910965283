@keyframes left_line_vertical {
  from {
    height: 0%;
    opacity: 1;
  }

  50% {
    opacity: 1;
    height: 100%;
  }

  to {
    height: 100%;
    opacity: 0;
  }
}

@keyframes line_vertical {
  from {
    height: 0%;
    opacity: 1;
  }

  20% {
    opacity: 1;
    height: 0%;
  }

  50% {
    opacity: 1;
    height: 100%;
  }

  to {
    height: 100%;
    opacity: 0;
  }
}

@keyframes line_horizontal {
  from {
    width: 0%;
    opacity: 1;
  }

  20% {
    width: 0%;
    opacity: 1;
  }

  35% {
    width: 100%;
    opacity: 1;
  }

  to {
    width: 100%;
    opacity: 0;
  }
}

@keyframes fill_vertical {
  from {
    height: 100%;
    opacity: 1;
  }

  50% {
    height: 100%;
    opacity: 1;
  }

  to {
    height: 0%;
  }
}

@keyframes fill_vertical2 {
  from {
    height: 100%;
    opacity: 1;
  }

  80% {
    height: 100%;
    opacity: 1;
  }

  to {
    height: 0%;
  }
}

@keyframes fill_vertical-to-middle {
  from {
    height: 100%;
    opacity: 1;
    transform: translateY(0%)
  }

  50% {
    height: 100%;
    opacity: 1;
    transform: translateY(0%)
  }

  to {
    height: 0%;
    transform: translateY(60%)
  }
}
@keyframes fill_vertical-to-middle-from-top {
  from {
    height: 100%;
    opacity: 1;
    transform: translateY(0%)
  }

  50% {
    height: 100%;
    opacity: 1;
    transform: translateY(0%)
  }

  to {
    height: 0%;
    transform: translateY(-60%)
  }
}