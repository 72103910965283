.swiperContainer3 {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  // flex-shrink: auto;
  .swiper-slide-active {
    width: 100%;
  }
  .mainWrapper_col_third_news_slider {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
    font-size: 1vw;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 69px 30px 30px;
    box-sizing: border-box;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    @media screen and (max-width: 1200px) {
      padding: 60px 30px 0px 12px;
    }
    .swiper-slide .swiper-slide-active{
      width: 100% !important;
    }
    .marker {
      width: max-content;
      height: 27px;
      background: #971811;
      opacity: 0.4;
      padding: 0 10px;
      p {
        display: block;
        text-transform: uppercase;
        font-family: 'Roboto', 'Arial', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 27px;
        color: #FFFFFF;
        text-align: center;
        margin: 0;
        padding: 0;
      }
    }
    .header {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: .9em;
      text-transform: uppercase;
      color: #FFFFFF;
      width: 130px;
      @media screen and (max-width: 1200px) {
        font-size: 14px;
        line-height: 19px;
      }
    }
    .text_content {
      .subheader {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        color: rgba(255, 255, 255, 0.6);
      }
      .text {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 120%;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  .swiper-pagination-bullet {
    background-color: #FF483F;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: #FFFFFF;
  }
  .swiper-pagination {
    text-align: left;
    padding-left: 27px;
    box-sizing: border-box;
    @media screen and (max-width: 1200px) {
      padding-left: 15px;
    }
  }
}
