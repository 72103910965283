@keyframes hide {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(80);
    }
}
.preloader {
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.preloadedImage {
    height: 113px;
    width: 225.92px;
    background: url(../../../assets/img/preloader/image60.webp) center/100% no-repeat;
}
.animatedZoom {
    animation: hide 2s ease 1 normal both;
}
