.aside {
    background-color: #45494E;
    width: 50px;
    height: 100%;
    position: relative;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    z-index: 9999;
    @media screen and (max-width: 1199px) {
      width: 100%;
      height: 60px;
      flex-direction: row;
      background-color: #45494E !important;
    }
    &_animated_fill {
        background-color: white;
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        right: 0;
        animation: fill_vertical_from_top 3s ease-in-out 1 normal both;
        z-index: 1;
    }
    &_search {
        background: url(../../../assets/img/mainScreen/search_aside.png) center center no-repeat;
        position: relative;
        width: 50px;
        height: 50px;
        margin-top: 8px;
        cursor: pointer;
        transition: all 0.5s ease;
      @media screen and (max-width: 1280px) {
        margin-top: 0;
      }
      &.hidden {
        opacity: 0;
      }
    }
    &_search-hidden-wrapper {
      display: none;
      width: 100vw;
      height: 100vh;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
      background-color: transparent;
      &.active {
        display: block;
      }
    }
    &_search-hidden {
      position: absolute;
      justify-content: space-between;
      align-items: center;
      left: 0;
      top: -90px;
      height: 67px;
      padding: 6px 8px 6px 50px;
      z-index: 10;
      background-color: #292F35;
      display: flex;
      transition: all 0.3s ease;
      width: calc((100vw - 50px) * 0.29 + 50px);
      @media screen and (max-width: 1280px) {
        height: 60px;
        width: 82%;
        z-index: 11;
      }
      &.active {
        top: 0px;
      }
      .input-icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: 67px;
        background: url(../../../assets/img/mainScreen/search_aside.png) center center no-repeat;
        @media screen and (max-width: 1280px) {
          height: 60px;
        }
      }
      input {
        width: 100%;
        height: 100%;
        background-color: #292F35;
        border: none;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-size: 25px;
        font-weight: 300;
        padding-left: 20px;
        position: relative;

        -webkit-user-select: initial;
        -khtml-user-select: initial;
        -moz-user-select: initial;
        -ms-user-select: initial;
        user-select: initial;
      }
      .search-button {
        width: 132px;
        height: 52px;
        background: rgba(255, 255, 255, 0.08);
        border-radius: 4px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 52px;
        text-align: center;
        color: #FFFFFF;
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 14px;
          left: 50px;
          height: 38px;
          width: 3px;
          background-color: #5A5863;
        }
      }
    }
    &_plus {
      font-size: 40px;
      font-weight: 300;
      color: #7F8190;
      @media screen and (max-width: 1280px) {
        display: none;
      }
    }
    &_pallete {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: max-content;
        margin-bottom: 90px;
        &_item {
            display: block;
            width: 20px;
            height: 20px;
            margin-top: 10px;
            &:nth-child(1) {
                background-color: #EB1C24;
            }
            &:nth-child(2) {
                background-color: #EE837F;
            }
            &:nth-child(3) {
                background-color: #E8E8EE;
            }
            &:nth-child(4) {
                background-color: #9497AA;
            }
            &:nth-child(5) {
                background-color: #FFFFFF;
            }
            &:nth-child(6) {
                background-color: #5A5863;
            }
        }

    }
}
.aside.white {
  .aside_search {
    background: url(../../../assets/img/mainScreen/search_aside_white_theme.png) center center no-repeat;
  }
}

.aside.green {
  .aside_plus {
    color: #ffffff;
  }
}

.aside_logo {
  display: none;
  @media screen and (max-width: 1199px) {
    display: block;
    width: 190px;
    height: 64px;
    margin: 0 auto;
  }
  @media screen and (max-width: 767px) {
    display: block;
    position: absolute;
    left: calc(50% - 64px);
  }
}
.aside_logo-tablet {
  @media screen and (max-width: 1280px) {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    background-size: 70% auto;
    width: 127px;
    height: 64px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-image: url("../../../assets/img/logo/logo_petruha.svg");
  }
  @media screen and (max-width: 400px) {
    background-size: 60% auto;
  }
}

@keyframes fill_vertical_from_top {
    from {
        height: 100%;
        opacity: 1;
    }
    60% {
        height: 100%;
        opacity: 1;
    }
    to {
        height: 0%;
    }
}


.aside{
  .searchpage{
    @media screen and (min-width: 768px){
      display: none;
    }
  }
}
