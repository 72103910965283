.recipesWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  position: relative;
  padding: 50px 0px 50px 50px;
  background-color: #4c5363;
  @media screen and (max-width: 1280px) {
    width: 100%;
    box-sizing: border-box;
    height: auto;
    min-height: 100%;
  }
  @media screen and (max-width: 600px) {
    padding: 20px 11px 0px;
    min-height: initial;
  }
  .logo {
    background-image: url("../../../assets/img/logo/logo_petruha.svg");
    //url(../../../../assets/img/mainScreen/master/6_italyanskie.webp)
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    width: 130px;
    height: 100px;
    margin-top: -27px;
    transition: opacity .5s
  }
  .logo:hover {
    opacity: 0.5;
  }
  .swiper-wrapper {
    @media screen and (max-width: 600px){
      margin-bottom: 30px;
    }
  }
  .swiper-pagination-bullets {
    position: absolute;
    left: 19px !important;
    bottom: 10px !important;
    text-align: left;
    display: none;
    @media screen and (max-width: 1199px) {
      display: block;
      left: 50px !important;
    }
    @media screen and (max-width: 600px) {
      // display: block;
      left: 19px !important;
    }
    .swiper-pagination-bullet {
      opacity: 1;
      margin-right: 10px;
      background-color: rgba(255, 255, 255, 0.4);
    }
    .swiper-pagination-bullet-active {
      background-color: red;
    }
  }

  &_header {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 100%;
    color: #FFFFFF;
    margin: 10px 0;
    @media screen and (max-width: 600px) {
      font-size: 45px;
      margin: 0;
      margin-left: 8px;
    }
  }
  &_selects {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 52px;

    margin-top: 15px;
    margin-bottom: 50px;
    position: relative;
    @media screen and (max-width: 1280px) {
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      margin-bottom: 98px;
      justify-content: space-between;
    }
    @media screen and (min-width: 320px) {
      margin: 0px auto;
      margin-top: 15px;
      margin-bottom: 90px;
    }
    @media screen and (min-width: 1200px) {
      margin: 0;
      margin-bottom: 20px;
    }
    @media screen and (max-width: 400px){
      width: 95%;
    }
    @media screen and (max-width: 600px){
      margin-bottom: 30px;
    }
    .ListItems {
      .OptionItem {
        .select-option {
          // background: #7f8190;;
          color: #fff;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
    .mobile-select-wrapper-mobile {
      display: none;
      height: 32px;
      @media screen and (max-width: 1280px) {
        display: block;
      }
      @media screen and (max-width: 480px) {
        // width: 100%;
      }
      @media screen and (max-width: 767px) {
        width: 228px;
      }
      .recipes-mobile-menu-select {
        height: 32px;
      }
    }

    &_select-item {
      margin-right: 2%;
      margin-left: -5px;
      @media screen and (max-width: 1600px) {
        margin-right: 75px;
      }
      @media screen and (max-width: 1400px) {
        margin-right: 50px;
      }
      @media screen and (max-width: 1199px) {
        margin-right: 15px;
      }
      @media screen and (max-width: 767px){
        display: none;
        // width: 0;
      }
      @media screen and (max-width: 600px) {
        margin-right: 0;
      }

      .rmsc .dropdown-container:focus-within{
        background-color:white;
        box-shadow: none;

        span{
          color: black !important;
        }

        .gray{
          color: black !important;
        }

        svg{
          color: black;
        }
      }

      // .rmsc:focus-within{
      //   background-color: white;
      // }

      .recipes-select{
        width: 275px;
        border-radius: 5px 5px 0px 0px;
        @media screen and (max-width: 1600px) {
          width: 230px;
        }
        @media screen and (max-width: 1400px) {
          width: 200px;
        }
        @media screen and (max-width: 1199px) {
          width: 400px;
        }
        @media screen and (max-width: 950px){
          width: auto;
        }
        // .select-item:hover{
        //   background: white;
        // }



        .custom__clicker{
          position: absolute;
          bottom: 0px;
          z-index: 60;
          width: 225px;

          @media screen and (max-width: 768px){
            width: 215px;
          }

          &_modal{
            display: flex;
            padding-top: 9px;
            padding-bottom: 9px;
            background-color: white;
            position: absolute;
            bottom: 0px;
            width: 100%;
            padding-left: 15px;
            & button:first-child{
              background: linear-gradient(266.85deg, #E1251B 47.39%, #EC392F 81%);
              box-shadow: 6px 7px 8px rgba(224, 92, 92, 0.2), inset -4px 4px 4px rgba(0, 0, 0, 0.06), inset 4px -2px 9px rgba(255, 255, 255, 0.36);
              border-radius: 4px;
              border: 0px;
              color: white;
              width: 120px;
              padding: 18px;
              font-size: 16px;
              line-height: 16px;
              font-weight: 700;
              margin-left: -15px;
              // transition: background .3s;

              &:hover{
                cursor: pointer;
              }
            }
            & button:last-child{
              background-color: white;
              color: #45494E;
              border: 0px;
              font-weight: 700;
              width: 80px;
              padding: 13px;
              font-size: 16px;
              line-height: 16px;
              font-weight: 700;
              cursor: pointer;
              margin-left: 10px;
            }
          }

        }

        .dropdown-container{
          background: none;
          border: none;
          border-radius: 0;
          display: flex;
          justify-content: center;
          // padding: 0px 10px;
          width: 275px;
          border-radius: 5px 5px 0px 0px;
          // padding-bottom: 5px;
          @media screen and (max-width: 1400px){
            width: 245px;
          }
          @media screen and (max-width: 1199px){
            width: 375px;
          }
          @media screen and (max-width: 950px){
            width: 302px;
          }
          @media screen and (max-width: 600px){
            width: calc(100vw - 22px);
            // padding: 0 11px 0 11px;
          }

          .dropdown-heading{
            // color: white;
            padding: 0 11px;
            padding-top: 20px;
            padding-left: 0px;
            padding-bottom: 17px;
            height: 32px;
            width: 94%;
            border-bottom: 1px solid #9497AA;
            // border-radius: 5px 5px 0px 0px;

            &-value{
              color: white;
              .gray{
                color: white
              }
            }

          }
        }

        .dropdown-content{
          // padding-top: 10px;
          scrollbar-color: #888 #f1f1f1;
          scrollbar-width: 6px;
          z-index: 10;
          margin-top: 0px;
          // height: 300px;
          border: 0px solid black;
          // background: white;

          /* width */
          ::-webkit-scrollbar {
            width: 6px;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #888;
          }

          .select-panel{
            scrollbar-color: #888 #f1f1f1;
            scrollbar-width: thin;
            border: 0px;
            background-color: white;
          }

          .item-renderer{
            display: flex;
            background-color: white;
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
            color: #45494E;
            align-items: flex-start;
            span{
              padding-bottom: 5px;
              margin-left: 10px;
            }
          }

          .options{
            scrollbar-color: #888 #f1f1f1;
            scrollbar-width: thin;
            border: 0px;
            border-radius: 0px;
            margin-top: 11px;
            li{
              height: 35px;
              margin-top: 10px;
            }

            li:last-child{
              height: 70px;
            }

          }

          .select-item{
            scrollbar-color: #888 #f1f1f1;
            scrollbar-width: 6px;
            background-color: white;
            input{
              border-radius: 5px;
              width: 19px;
              height: 19px;
              border: 2px solid #9497AA;
            }
          }

          .panel-content{
            scrollbar-color: #888 #f1f1f1;
            scrollbar-width: thin;
            border: none;
            box-shadow: none !important;
            width: 100%;
            margin-top: -8px;
            border-radius: 0px 0px 5px 5px;
          }

        }

      }
    }
    &_button {
      width: 94%;
      height: 52px;
      margin: 0 auto;
      border: 2px solid #9497AA;
      box-sizing: border-box;
      border-radius: 4px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      color: #FFFFFF;
      cursor: pointer;
      background-color: transparent;
      @media screen and (max-width: 1199px){
        position: absolute;
        width: 30%;
        bottom: -85px;
        left: 0;
      }
      @media screen and (max-width: 480px) {
        width: 70%;
      }
      @media screen and (min-width: 320px) {
        width: 100%;
      }
      @media screen and (min-width: 767px) {
        width: 97%;
      }
      @media screen and (min-width: 1300px) {
        width: 222px;
        margin: 0;
      }
      @media screen and (min-width: 1200px) {
        width: 222px;
        margin: 0;
        margin-top: 15px;
      }
      &:hover {
        background-color: rgba(243, 235, 235, 0.24);
      }
    }
    &_clear-button{
      background: none;
      border: none;
      color: white;
      margin-left: 22px;
      height: 52px;
      cursor: pointer;
      padding: 0px 20px;
      font-size: 16px;
      line-height: 16px;
      font-weight: 700;

      .clear_icon{
        display: inline-block;
        width: 12px;
        height: 12px;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1L1 13' stroke='white' stroke-width='2'/%3E%3Cpath d='M1 1L13 13' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");;
        background-position: center;
        margin-right: 9px;
      }

      @media screen and (max-width: 1280px) {
        margin-left: 8px;
        margin-top: 15px;
        padding: 0;
      }
    }
    &_button-mobile {
      display: none;
      @media screen and (max-width: 1280px) {
        display: block;
        width: 41px;
        height: 32px;
        // position: absolute;
        margin-top: 5px;
        right: 0px;
        bottom: 0;
        background: url('../../../assets/img/recipes/sort-button.svg') center/contain no-repeat;
      }
    }

    .active{
      background: url('../../../assets/img/recipes/sort-button_active.svg') center/contain no-repeat;
    }
  }
  &_content {
    width: 100%;
    height: 100%;
    //display: flex;
    //align-items: stretch;
    //justify-content: flex-start;
    position: relative;
    @media screen and (max-width: 1280px){
      min-height: 417px;
    }
    @media screen and (max-width: 600px){
      margin-bottom: 20px;
      padding: 0px 9px;
    }
    .recipesSwiper {
      height: 100%;
      margin-left: 0;
      .swiper-slide {
        flex-shrink: 0;
      }
      .empty-slide {
        width: 100%;
        height: 100%;
        color: #ffffff;
      }
    }
    .cover {
      width: 333px;
      height: 617px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 24px 20px 12px;
      margin-right: 50px;
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
      transition: all ease 1s;
      @media screen and (max-width: 1400px) {
        width: 333px;
        height: 617px;
      }
      @media screen and (max-height: 1080px) {
        height: 100%;
      }
      @media screen and (max-width: 1280px) {
        width: 333px;
        height: 617px;
      }
      @media screen and (max-width: 600px) {
        width: calc(100vw - 22px);
        height: 417px;
      }
      @media screen and (max-width: 400px) {
        width: 100%;
      }
      &:hover {
        transition: all ease 1s;
        background-size: 120%;
      }
      .name {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 100%;
        color: #FFFFFF;
        @media screen and (max-width: 600px) {
          display: block;
          width: 100%;
          word-wrap: anywhere;
        }
      }
      .bottom-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: max-content;
        .time, .calories {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: max-content;
          margin-right: 7px;
          .icon {
            width: 52px;
            height: 52px;
            margin-right: 4px;
            background: url(../../../assets/img/recipes/timeIconGreen.png) center center no-repeat;
          }
          .value {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 120%;
            color: #FFFFFF;
          }
        }
        .calories {
          .icon {
            background: url(../../../assets/img/recipes/caloriesIcon.png) center center no-repeat;
          }
        }
      }
    }
    .ratatui {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.77) 0%, rgba(0, 0, 0, 0) 100%),
      url(../../../assets/img/recipes/ratatui.jpg);
    }
    .pirozhki {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.77) 0%, rgba(0, 0, 0, 0) 100%),
      url(../../../assets/img/recipes/pirozhki.jpg);
    }
    .shariki {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.77) 0%, rgba(0, 0, 0, 0) 100%),
      url(../../../assets/img/recipes/shariki.jpg);
    }
    .cesar {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.77) 0%, rgba(0, 0, 0, 0) 100%),
      url(../../../assets/img/recipes/grecheskii.jpg);
    }
  }
}


.item-renderer{
  input{
    display: none;
  }
}

.custom_input + .custom_input_wrapper{
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.custom_input_wrapper::before{
  content: "";
  opacity: 1;
  width: 15px;
  display: inline-block;
  height: 15px;
  border: 2px solid #9497AA;
  border-radius: 5px;
  flex-shrink: 0;
  flex-grow: 0;
}

.custom_input:checked+.custom_input_wrapper::before{
  border: 2px solid #E1251B;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 4.5L4.66667 7L10 2' stroke='%23E1251B' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");;
}

.toggled .input input.recipes-select{
  height: 38px !important;
  padding-left: 10px;
  width: auto !important;
}

.ListItems .OptionItem{
  border: 0 !important
}

.recipesWrapper_selects .ListItems .OptionItem .select-option{
  background-color: white;
  color: #45494E;
  font-size: 15px;
  font-weight: 400;
}

.toggled .input input.recipes-select:focus{
  background: white;
  // color: #45494E !important;
  color: red !important;
  border-bottom: none;
}


.recipesWrapper_selects_button-mobile{
  float: right;
}

.select-mobile-row{
  display: none;
  @media screen and (max-width: 767px){
    display: flex;
  }
}

.select-mobile-row{

  .dropdown-content{
    .mobile{
      & button:first-child{
        font-size: 14px;
        width: 100px;
        padding: 13px 0px;
        text-align: center;
      }
      & button:last-child{
        font-size: 14px;
        width: 100px;
        text-align: center;
        margin-left: 10px;
      }
    }
  }

  .dropdown-container{
    @media screen and (max-width: 950px){
      width: 228px !important;
    }
  }
}

.links{
  display: contents;
}

.filter-button{
  @media screen and (max-width: 1280px){
    margin-right: 50px;
  }
}

.filter-button-clear{
  @media screen and (max-width: 1280px){
    position: absolute;
    bottom: -70px;
  }
}

