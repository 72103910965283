@import '../../../assets/styles/_default/variables';
@import '../../../assets/styles/_mixins/breakpoint';

.promoWrapper {
  width: 100%;
  // min-height: calc(100vh - 90px);
  min-height: calc(100vh - 259px);
  display: block;
  position: relative;
  background-color: #ffffff;

  .promo {
    width: 100%;
    min-height: 790px;
    position: relative;
    background-color: #393F45;
    padding-top: 132px;
    overflow: hidden;

    @media screen and (max-width: 1199px) {
      padding-top: 40px;
    }

    @include breakpoint($tablet__max) {
      padding-left: 19px;
      padding-right: 19px;
    }

    @include breakpoint($mobileS__max) {
      padding-top: 50px;
      min-height: 650px;
      padding-left: 0;
    }

    &_logo {
      display: block;
      position: absolute;
      top: 20px;
      left: 48px;
      width: 127px;
      height: 64px;
      background: url("../../../assets/img/petruhaOnline/logo.svg") center center no-repeat;
      cursor: pointer;
    }

    .petruhaOnlineWrapper_logo {
      @media screen and (max-width: 1199px) {
        display: none;
      }
    }
    .promo-header {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 64px;
      line-height: 100%;
      color: #FFFFFF;
      margin-bottom: 40px;
      margin-left: 45px;

      @include breakpoint($mobileS__max) {
        font-weight: 400;
        font-size: 45px;
        line-height: 100%;
        margin: 0 0 38px 20px;
      }
    }
    .promo-swiper-wrapper {
      width: 100%;
      min-height: 450px;
      //padding-left: 140px;
      margin-left: 140px;
      box-sizing: border-box;
      @media screen and (max-width: 1800px) {
        margin-left: 45px;
      }
      @media screen and (max-width: 1280px) {
        margin-left: 0;
      }
      @include breakpoint($mobileS__max) {
        padding-bottom: 0px;
      }
      .promoSwiper {
        padding-bottom: 30px;
        .swiper-pagination {
          text-align: left;
          bottom: 0px;
          width: 100%;
          display: none;
          @media screen and (max-width: 1800px) {
            display: block;
          }
          @media screen and (max-width: 1280px) {
            // text-align: center;
            margin-left: 40px;
            bottom: 37px;
          }
          @media screen and (max-width: 1199px) {
            text-align: left;
            margin-left: 40px;
          }
          @media screen and (max-width: 767px) {
            margin-left: 21px;
          }
          .swiper-pagination-bullet {
            //background-color: rgba(255, 255, 255, 0.4);
            //background: rgba(57, 63, 69, 0.26);
            background: #CCCDCF;
            margin: 0 5px;
            text-align: left;
            &:first-child {
              margin-left: 0;
            }
            &-active {
              background-color: #E1251B;
            }
          }
        }
      }

      .promotions-slide-wrapper {
        display: flex;
        justify-content: flex-start;
        @media screen and (max-width: 1280px) {
          flex-direction: column;
          // align-items: center;
          align-items: flex-start;
          padding-left: 40px;
        }
        @media screen and (max-width: 767px) {
          padding-left: 20px;
        }
        .icon {
          width: 764px;
          height: 450px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          @media screen and (max-width: 1280px) {
          flex-direction: column;
          align-items: flex-start;
        }
          @media screen and (max-width: 845px) {
            width: 99%;
            height: 100%;
            min-height: 450px;
            background-position: left;
          }
          @include breakpoint($mobileS__max) {
            min-height: 350px;
          }
          @media screen and (max-width: 650px) {
            min-height: 250px;
          }
          @media screen and (max-width: 470px) {
            height: auto;
            min-height: 166px;
            background-size: cover;
            // background-position: left;
          }
        }
        .content {
          width: 403px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          padding: 30px;
          @media screen and (max-width: 1280px) {
            width: 764px;
            padding-left: 0;
          }
          @media screen and (max-width: 805px) {
            width: 100%;
          }
          @include breakpoint($mobileS__max) {
            padding: 25px 0 40px 0;
          }

          .header {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 100%;
            color: #FFFFFF;
            @media screen and (max-width: 1280px) {
              margin-bottom: 20px;
            }
            // @include breakpoint($tabletS__max) {
            //   margin-bottom: 20px;
            // }
            @include breakpoint($mobileS__max) {
              font-weight: 500;
              font-size: 16px;
              line-height: 100%;
              height: 48px;
            }
          }
          .logo {
            width: 163px;
            height: 70px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            @media screen and (max-width: 1280px) {
              margin-bottom: 20px;
            }
            @include breakpoint($mobileS__max) {
              width: 132px;
              height: 70px;
            }
          }
          .date {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 23px;
            line-height: 100%;
            color: rgba(255, 255, 255, 0.34);

            @media screen and (max-width: 1280px) {
              margin-bottom: 20px;
            }
            @include breakpoint($mobileS__max) {
              font-weight: 400;
              font-size: 18px;
              line-height: 120%;
            }
          }
          .text {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            color: #FFFFFF;
            @media screen and (max-width: 1280px) {
              margin-bottom: 20px;
            }
            @include breakpoint($mobileS__max) {
              font-weight: 400;
              font-size: 14px;
              line-height: 120%;
              height: 96px;
              overflow: hidden;
            }
          }
          .more {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 120%;
            text-decoration-line: underline;
            color: #E1251B;
            cursor: pointer;
            @include breakpoint($mobileS__max) {
              font-size: 14px;
              line-height: 120%;
            }
          }
        }
      }
    }
  }
  .news {
    width: 100%;
    background-color: #ffffff;
    position: relative;
    .logo {
      background-image: url("../../../assets/img/logo/logo_petruha.svg");
      //url(../../../../assets/img/mainScreen/master/6_italyanskie.webp)
      background-repeat: no-repeat;
      background-position: center;
      display: block;
      width: 130px;
      height: 100px;
      margin-left: 45px;
      transition: opacity .5s
    }
    .logo:hover {
      opacity: 0.5;
    }
    @media screen and (max-width: 767px) {
      margin-top: 90px;
    }
    .news-region {
      margin-left: 45px;
      margin-bottom: 100px;
      color: #e1251ba8;

      .news-region-all {
        cursor: pointer;
        margin-left: 15px;
        color:gray;
      }
      .news-region-all:hover {
        color: black;
      }
      @media screen and (max-width: 767px) {
        margin-left: 15px;
        margin-bottom: 10px;
        font-size: 11px;
      }
    }
    .news-region-null {
      margin-bottom: 129px;
      @media screen and (max-width: 767px) {
        margin-bottom: 33px;
      }
    }

    .news-select, .news-select-bottom {
      position: absolute;
      right: 100px;
      margin-top: 27px;
      margin-bottom: 60px;
      top: 85px;
      width: 245px;
      height: 30px;
      padding: 0px 19px;
      span{
        width: 11px;
        height: 11px;
        margin-right: 5px;
      }
      .select{
        width: 100% !important;
        padding-left: 0px;
      }
      input {
        width: 90%;
        padding-right: 0px;
        height: 30px;
        border: none;
        border-bottom: 2px solid #9497AA;
        position: relative;
        color: gray !important;
        &::placeholder {
          color: gray !important;
        }
      }
      .toggled {

        width: 100%;
        height: 38px;
        .select-arrow {
          background: url('../../../assets/img/petruhaBrandContent/select-arrow.png') center center no-repeat;
        }
        &.select-opened {
          // color: #E1251B;
          .select-arrow {
            transform: translateY(-50%) rotate(180deg);
            transition: transform .3s ease;
          }
        }
      }
    }
    .news-select {
      @media screen and (max-width: 1199px) {
        display: none;
      }
      @media screen and (max-width: 767px) {
        display: block;
        right: 0;
        top: -90px;
        width: 100%;
        padding: 0px 19px;
      }
    }
    .news-select-bottom {
      display: none;
      @media screen and (max-width: 1199px) {
        display: flex;
        position: unset;
        justify-content: center;
        width: 340px;
        padding: 0 45px;
        margin-top: 0;
        margin-bottom: 30px;
      }
      @media screen and (max-width: 767px) {
        display: none;
        // width: 100%;
        // padding: 0px 19px;
      }
    }
    .header {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 64px;
      line-height: 102px;
      color: #000000;
      margin-bottom: 10px;
      margin-left: 45px;

      @media screen and (max-width: 1199px) {
        margin-bottom: 20px;
      }
      @include breakpoint($mobileS__max) {
        font-weight: 300;
        font-size: 36px;
        line-height: 100%;
        margin-bottom: 10px;
        margin-left: 15px;
        margin-top: 70px;
        //margin-right: auto;
        //padding-left: 15px;
        //width: 316px;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .top {
        display: flex;
        justify-content: space-between;
        height: 478px;
        margin-bottom: 25px;
        padding-right: 100px;

        @media screen and (max-width: 1199px) {
          display: none;
        }
        .text {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .header {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 55px;
            line-height: 100%;
            color: #000000;
            margin-left: 135px;
            @media screen and (max-width: 1800px) {
              margin-left: 45px;
            }
          }
          .article-content {
            padding-left: 137px;
            height: 370px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            @media screen and (max-width: 1800px) {
              padding-left: 45px;
            }
            .date {
              font-family: 'Roboto', sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 23.1892px;
              line-height: 120%;
              color: rgba(69, 73, 78, 0.34);
            }
            .text-content {
              width: 561px;
              font-family: Roboto;
              font-style: normal;
              font-weight: 300;
              font-size: 24px;
              line-height: 150%;
              color: #000000;
            }
            .more {
              width: 186px;
              height: 52px;
              background: linear-gradient(266.85deg, #E1251B 47.39%, #EC392F 81%);
              box-shadow: 6px 7px 8px rgba(224, 92, 92, 0.2), inset -4px 4px 4px rgba(0, 0, 0, 0.06), inset 4px -2px 9px rgba(255, 255, 255, 0.36);
              border-radius: 4px;
              font-family: 'Roboto', sans-serif;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              color: #FFFFFF;
              cursor: pointer;
            }
          }
        }
        .image {
          width: 850px;
          height: 478px;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          background-image: url('../../../assets/img/promotions/news/main-background.jpg');
          background-size: cover;
        }
      }
      .bottom {
        width: 100%;
        padding-left: 90px;
        @media screen and (max-width: 1800px) {
          padding-left: 0px;
        }
        @media screen and (max-width: 1199px) {
          padding-left: 40px;
        }
        @include breakpoint($mobileS__max) {
          padding-left: 0;
        }

        //.swiper-slide {
        //  width: 100vw !important;
        //}
        .newsSwiper {
          padding-left: 30px;
          padding-top: 30px;
          padding-bottom: 40px;
          @media screen and (max-width: 1199px) {
            padding-left: 0;
            padding-bottom: 125px;
          }
          @media screen and (max-width: 767px) {
            padding-top: 0px;
          }
          @media screen and (max-width: 450px) {
            // padding-bottom: 145px;
            padding-bottom: 0px;
          }
          @media screen and (max-width: 350px) {
            // padding-bottom: 200px;
            padding-bottom: 0px;
            padding-left: 0;
          }
          .swiper-slide {
            @media screen and (max-width: 767px){
              height: auto;
            }
          }
          .swiper-pagination {
            text-align: left;
            bottom: 37px;
            width: 100%;
            display: none;
            @media screen and (max-width: 1800px) {
              display: block;
              margin-left: 30px;
              opacity: 1;
            }
            @media screen and (max-width: 1280px) {
              text-align: left;
              bottom: 37px;
              margin-left: 30px;
            }
            @media screen and (max-width: 1199px) {
              margin-left: 0;
            }
            @media screen and (max-width: 800px) {
              text-align: left;
            }
            @media screen and (max-width: 767px) {
              width: 65px;
              bottom: 0px;
              margin-left: 40px;
              position: initial;
              margin-top: 5px;
              margin-bottom: 5px;
            }
            // @media screen and (max-width: 450px) {
            //   bottom: 40px;
            //   margin-left: 15px;
            // }
            // @media screen and (max-width: 350px) {
            //   bottom: 45px;
            // }
            .swiper-pagination-bullet {
              //background-color: rgba(255, 255, 255, 0.4);
              //background: rgba(57, 63, 69, 0.26);
              background: #CCCDCF;
              margin: 0 5px;
              text-align: left;
              &:first-child {
                margin-left: 0;
              }
              &-active {
                background-color: #E1251B;
              }
            }
          }
        }
        .news-slide-wrapper {
          width: 316px;
          min-height: 363px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 35px;
          padding: 15px;
          cursor: pointer;

          @media screen and (max-width: 1199px) {
            padding-left: 0;
          }
          @media screen and (max-width: 1199px) {
            padding-left: 0;
          }
          @include breakpoint($mobileS__max) {
            padding-left: 15px;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            //max-width: 316px;
          }

          &.current-slide {
            box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.1);

            // @include breakpoint($mobileS__max) {
            //   box-shadow: none;
            // }
            @media screen and (max-width: 1199px) {
              box-shadow: none;
            }
          }
          .icon {
            width: 286px;
            height: 161px;
            background-size: cover;
            max-width: 100%;
            margin-bottom: 22px;
            @media screen and (max-width: 767px) {
              width: 100vw;
              height: calc(100vw / 505 * 328 - 30px);
            }
          }
          .news-slide-header {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: #000000;
            margin-bottom: 16px;

            @include breakpoint($mobileS__max) {
              font-size: 23px;
              line-height: 120%;
            }
          }
          .date {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 19px;
            line-height: 120%;
            color: rgba(69, 73, 78, 0.34);
            margin-bottom: 16px;

            @include breakpoint($mobileS__max) {
              font-size: 23px;
              line-height: 120%;
            }
          }
          .text {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            color: #585858;

            @include breakpoint($mobileS__max) {
              font-size: 19px;
              line-height: 120%;
            }
          }
        }
      }
    }

    //.swiper-pagination {
    //  bottom: 40px;
    //  padding-left: 15px;
    //  .swiper-pagination-bullet {
    //    opacity: 1;
    //    &-active {
    //    }
    //  }
    //}
  }


}


