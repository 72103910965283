@import '../../../../assets/styles/_default/variables';
@import '../../../../assets/styles/_mixins/breakpoint';

.button:not(.select-option) {
  text-transform: uppercase;
  text-align: center;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.7em 0.5em 0.9em 0.5em;
  min-width: 267px;
  font-size: 16px;
  font-family: $font-family__default;
  font-weight: 900;
  background-color: $red;
  border: 2px solid $black;
  border-radius: 6px;
  transition: background-color 300ms ease, opacity 300ms ease, color 300ms ease;

  &::after {
    content: "";
    position: absolute;
    width: 99%;
    height: 89%;
    left: -1px;
    top: -1px;
    border: 1.5px solid $black;
    border-radius: 6px;
  }

  &:active {
    background-color: $yellow;
  }

  &:disabled, .disabled {
    cursor: not-allowed;
    color: currentColor !important;
    background-color: $violet;
  }

  span {
    position: relative;
    z-index: 2;
    width: 100%;
    line-height: 1;
    text-shadow: 1px 1px 2px black;
    display: block;
    color: #fff;
    transition: opacity 300ms ease, color 300ms ease;
  }

  @include breakpoint($tabletS) {
    padding-top: .9em;
    padding-bottom: 1em;
    border-width: 4px;

    &::after {
      width: 98%;
      border-width: 2px;
    }

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      background-color: $yellow;
    }
  }
}

.link {
  cursor: pointer;
  background: none;
  border: none;
  color: currentColor;
  border-bottom: 1px solid $brown;
  line-height: 1.1;
  display: inline-block;
  transition: border-color .2s ease;

  img {
    cursor: pointer;
  }
}
