.swiperContainer7 {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  max-width: 100%;
  // align-items: stretch;
  box-sizing: border-box;
  .mainWrapper_col_7_sausages_slider {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    height: 100%;
    background-color: #45494E;
    padding: 15px 20px 30px;
    box-sizing: border-box;
    background: #F1F1F1;
    .slide {
      display: block;
      width: 100%;
      height: 100%;
    }
    .slide-1{
      background: url(../../../../assets/img/mainScreen/sausages/1_milanskie.webp) center/contain no-repeat;
    }
    .slide-2 {
      background: url(../../../../assets/img/mainScreen/sausages/2_munchen.webp) center/contain no-repeat;
    }
    .slide-3 {
      background: url(../../../../assets/img/mainScreen/sausages/3_kabanosy.webp) center/contain no-repeat;
    }
    .slide-4 {
      background: url(../../../../assets/img/mainScreen/sausages/4_palermo.webp) center/contain no-repeat;
    }
    .slide-5 {
      background: url(../../../../assets/img/mainScreen/sausages/5_cheese.webp) center/contain no-repeat;
    }
    .slide-6 {
      background: url(../../../../assets/img/mainScreen/sausages/6_studen.webp) center/contain no-repeat;
    }
    // .slide-7 {
    //   background: url(../../../../assets/img/mainScreen/sausages/7_mini.webp) center/contain no-repeat;
    // }
  }
  .swiper-pagination-bullet {
    background-color: #FFFFFF;
  }
  .swiper-pagination-bullet-active {
    background-color: #FF483F;
  }
  .swiper-pagination {
    text-align: center;
    padding-left: 15px;
    box-sizing: border-box;
  }
}
