.videoPopup {
  position: absolute;
  top: 0;
  left: 0;
  background: #3B4250aa;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  transform: translate(-110vw, 0);
  width: 100vw;
  height: 100vh;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 1199px) {
    position: fixed;
  }
  .popup_content {
    width: 70%;
    height: 60%;
    @media screen and(max-width: 600px) {
      width: 450px;
    }
    @media screen and(max-width: 479px) {
      width: 400px;
    }
    @media screen and(max-width: 429px) {
      width: 350px;
    }
    @media screen and(max-width: 350px) {
      width: 300px;
    }
  }
  iframe {
    width: 100% !important;
    height: 100% !important;
    @media screen and(max-width: 600px) {
      width: 100%;
    }
  }
  &.show {
    transform: translate(0, 0)
  }
}
.emptyUrl {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: #3B4250aa;
  font-size: 2rem;
  z-index: 13;
}
