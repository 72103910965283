.burgerWrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(190, 190, 190, .05);
  right: 0px;
  top: 0px;
  transform: translateX(100vw);
  transition: all ease 1s;
  z-index: 99999;
  .burgerLine {
    width: 300px;
    position: absolute;
    height: 100vh;
    background-color: #ffffff;
    right: 0px;
    top: 0px;
    transform: translateX(300px);
    transition: all ease 1s;
    z-index: 2;
    display: flex;
    padding: 100px 0px 20px 30px;
    flex-direction: column;
    justify-content: flex-start;
    @media screen and (max-width: 1200px) {
      padding-top: 60px;
    }
    &.green {
      background-color: #79279d;
      @media screen and (max-width: 1200px) {
        background-color: #45494E;
      }
      .menu-item {
        color: #ffffff;
      }
    }
    &.gray {
      background-color: #3f403e;
      .menu-item {
        color: #ffffff;
      }
    }
    .menu-item {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      letter-spacing: -0.03em;
      text-transform: uppercase;
      color: #4c5363;
      line-height: 45px;
      cursor: pointer;
      &:hover {
        opacity: .8;
      }
    }
  }
  &.animated {
    transform: translateX(0vw);
    .burgerLine {
      transform: translateX(0px);
    }
  }
}

.burger-icon {
  width: 64px;
  height: 64px;
  background-color: #ffffff;
  position: absolute;
  top: 27px;
  right: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 11;
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15));
  background-color: rgba(204, 204, 204, 0.376);
  @media screen and (max-width: 1199px) {
    width: 60px;
    height: 60px;
    top: 0;
    right: 0;
    background-color: transparent;
    z-index: 99999;
  }
  .line {
    position: absolute;
    width: 32px;
    height: 2px;
    background-color: #E1251B;
    border-radius: 1px;
    transition: all ease 1s;
    @media screen and (max-width: 1280px) {
      background-color: #ffffff;
    }
    &:nth-child(1) {
      top: 40%;
      left: 50%;
      transform: translate(-50%, -40%);
    }
    &:nth-child(2) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:nth-child(3) {
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &.animated {
    background-color: #ffffffaa;
    transition: all ease-in-out 1s;
    @media screen and (max-width: 1280px) {
      background-color: #7d7d7d;
      border-radius: 0;
    }
    .line {
      &:nth-child(1) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}
