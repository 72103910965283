.footer {
  width: 100%;
  height: 85px;
  position: relative;
  bottom: 0;
  box-sizing: border-box;
  background-color: #45494e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  flex: 1 0 85px;
  overflow: hidden;
  @media screen and (max-width: 1199px) {
    padding-right: 13px;
    padding-left: 70px;
    flex: 1 0 114px;
    background-color: #45494e !important;
  }
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    flex-flow: wrap;
    -webkit-flex-flow: row wrap;
    justify-content: center;
    padding-top: 19px;
    height: 114px;
  }
  @media screen and (max-width: 500px) {
    z-index: 15;
  }

  &_item {
    width: max-content;

    &.centered {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 480px;
      margin-right: 200px;
      @media screen and (max-width: 1700px) {
        margin-right: 0px;
      }
      @media screen and (max-width: 1199px) {
        order: 0;
        -webkit-order: 0;
        width: 30%;
        justify-content: space-between;
      }
      @media screen and (max-width: 980px) {
        width: 40%;
      }
      @media screen and (max-width: 825px) {
        width: 65%;
      }
      @media screen and (max-width: 600px) {
        width: 50%;
        // flex-direction: row-reverse;
        justify-content: flex-start;
      }
      @media screen and (max-width: 480px) {
        width: 62%;
      }
      @media screen and (max-width: 350px) {
        width: 70%;
      }
    }
  }

  &_contacts,
  &_with-love {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    box-sizing: border-box;
    position: relative;

    p {
      margin: 0;
      padding: 0;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #7f8190;

      a {
        color: #7F8190 !important;
      }

      &.plus {
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-size: 40px;
        font-weight: 300;
        @media screen and (max-width: 1199px) {
          display: none;
        }
      }
    }
  }

  &_contacts {
    @media screen and (max-width: 1199px) {
      margin-right: 20px;
      margin-left: -42px;
      height: auto;
      p {
        font-size: 9px;
        line-height: 11px;
        color: #7f8190 !important;

        a {
          color: #7f8190 !important;
        }
      }
    }
    @media screen and (max-width: 600px) {
      order: 1;
    }
    @media screen and (max-width: 380px) {
      margin-right: 5px;
      margin-left: -72px;
    }
  }

  &_with-love {
    padding-right: 50px;
    @media screen and (max-width: 1199px) {
      padding: 0;
      height: auto;
      p {
        font-size: 9px;
        line-height: 11px;
        color: #7f8190 !important;

        a {
          color: #7F8190 !important;
        }
      }
    }
    @media screen and (max-width: 600px) {
      order: 1;
    }
  }

  &_social-icons {
    margin-left: 10px;
    width: max-content;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_social-icon {
      width: 52px;
      height: 52px;
      margin-right: 5px;
      margin-left: 5px;
      cursor: pointer;
      @media screen and (max-width: 480px) {
        margin-right: 2px;
        margin-left: 2px;
      }

      &.vk {
        background: url(../../../assets/img/mainScreen/vkGroup.svg) center/contain no-repeat;
      }

      &.ok {
        background: url(../../../assets/img/mainScreen/okGroup.svg) center/contain no-repeat;
      }

      &.youtube {
        background: url(../../../assets/img/mainScreen/youtube.svg) center/contain no-repeat;
      }

      &.tiktok {
        background: url(../../../assets/img/mainScreen/tik-tok.svg) center/contain no-repeat;
      }

      &.fb {
        background: url(../../../assets/img/mainScreen/fb.svg) center/contain no-repeat;
      }

      &.instagram {
        background: url(../../../assets/img/mainScreen/instagram.svg) center/contain no-repeat;
      }

      @media screen and (max-width: 1199px) {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }
  }

  &_item_dialog {
    width: 147px;
    height: 52px;
    background: url(../../../assets/img/mainScreen/dialog.png) center center no-repeat;
    cursor: pointer;
    @media screen and (max-width: 1199px) {
      width: 84px;
      height: 29.71px;
      background-size: contain;
    }
    @media screen and (max-width: 1199px) {
      flex-shrink: 0;
      flex-basis: 84px;
      background-size: 95px !important;
      border-radius: 15px;
    }
  }

  &_languages {
    display: flex;
    width: max-content;
    justify-content: space-between;
    align-items: center;
    margin-right: -200px;
    @media screen and (max-width: 1700px) {
      margin-right: 0;
      justify-content: flex-start;
    }
    @media screen and (max-width: 1199px) {
      width: 20%;
      justify-content: center;
      margin-left: 10px;
    }
    @media screen and (max-width: 980px) {
      width: 40%;
    }
    @media screen and (max-width: 825px) {
      width: 35%;
    }
    @media screen and (max-width: 600px) {
      width: 15%;
    }
    @media screen and (max-width: 480px) {
      width: 27%;
    }
    @media screen and (max-width: 400px) {
      width: 23%;
    }
    @media screen and (max-width: 350px) {
      width: 20%;
    }
    @media screen and (max-width: 330px) {
    }

    &_item {
      margin-left: 4px;
      margin-right: 4px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #7f8190;
      @media screen and (max-width: 480px) {
        font-size: 12px;
        font-weight: 400;
      }

      &.current {
        // color: white;
        transform: scale(1.1);
      }
    }
  }

  &_line_horizontal_top {
    background-color: red;
    position: absolute;
    width: 0px;
    height: 1px;
    top: 0;
    left: 0;
    animation: line_horizontal 3s ease-in-out 1 normal both;
    z-index: 2;
  }

  &_animated_fill {
    background-color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    animation: fill_horizontal 3s ease-in-out 1 normal both;
    z-index: 1;
  }
}

.footer.white {
  .footer_contacts,
  .footer_with-love {
    // p {
    //   color: #B2B4C3;
    // }
  }

  .footer_item_dialog {
    background: url(../../../assets/img/mainScreen/dialogWhiteFooter.png) center center no-repeat;
  }

  .footer_social-icons {
    &_social-icon {

      &.vk {
        background: url(../../../assets/img/mainScreen/vkGroupWhiteFooter.svg) center/contain no-repeat;
      }

      &.ok {
        background: url(../../../assets/img/mainScreen/okGroupWhiteFooter.svg) center/contain no-repeat;
      }

      &.youtube {
        background: url(../../../assets/img/mainScreen/youtube_WhiteFooter.svg) center/contain no-repeat;
      }

      &.tiktok {
        background: url(../../../assets/img/mainScreen/tik-tok_WhiteFooter.svg) center/contain no-repeat;
      }

      &.fb {
        background: url(../../../assets/img/mainScreen/fbGroupWhiteFooter.svg) center/contain no-repeat;
      }

      &.instagram {
        background: url(../../../assets/img/mainScreen/instaGroupWhiteFooter.svg) center/contain no-repeat;
      }
    }
  }

  .footer_languages {
    // &_item {
    //   color: #B2B4C3;
    // }
    .ru {
      color: #564656;
      transform: scale(1);
    }
  }
}

.footer.black,
.footer.green {
  .footer_contacts,
  .footer_with-love {
    p {
      color: #7f8190;

      a {
        color: #7F8190 !important;
      }
    }
  }

  @media screen and (min-width: 1199px) {
    .footer_item_dialog {
      background: url(../../../assets/img/mainScreen/dialogBlackFooter.svg) center center no-repeat;
    }
    .footer_social-icons {
      &_social-icon {
        &.vk {
          background: url(../../../assets/img/mainScreen/vk-green.svg) center/contain no-repeat;
        }

        &.ok {
          background: url(../../../assets/img/mainScreen/ok-green.svg) center/contain no-repeat;
        }

        &.youtube {
          background: url(../../../assets/img/mainScreen/youtube_BlackFooter.svg) center/contain no-repeat;
        }

        &.tiktok {
          background: url(../../../assets/img/mainScreen/tik-tok_BlackFooter.svg) center/contain no-repeat;
        }

        &.fb {
          background: url(../../../assets/img/mainScreen/fbGroupBlackFooter.svg) center/contain no-repeat;
        }

        &.instagram {
          background: url(../../../assets/img/mainScreen/instaGroupBlackFooter.svg) center/contain no-repeat;
        }
      }
    }
  }

  .footer_languages {
    &_item {
      color: #ffffff;
    }

    .ru {
      color: #7f8190;
      transform: scale(1);
    }
  }
}

.footer.green {
  .footer_with-love {
    p {
      color: #ffffff;
    }
  }

  .footer_contacts {
    p {
      color: #ffffff;
    }
  }

  @media screen and (min-width: 1199px) {
    p {
      color: #7f8190;

      a {
        color: #7F8190 !important;
      }
    }
  }
}

.footer.dark {
  .footer_social-icons {
    &_social-icon {
      &.vk {
        background: url(../../../assets/img/mainScreen/vkGroupDarkFooter.svg) center/contain no-repeat;
      }

      &.ok {
        background: url(../../../assets/img/mainScreen/okGroupDarkFooter.svg) center/contain no-repeat;
      }

      &.youtube {
        background: url(../../../assets/img/mainScreen/youtube_DarkFooter.svg) center/contain no-repeat;
      }

      &.tiktok {
        background: url(../../../assets/img/mainScreen/tik-tokDarkFooter.svg) center/contain no-repeat;
      }

      &.fb {
        background: url(../../../assets/img/mainScreen/fbGroupDarkFooter.svg) center/contain no-repeat;
      }

      &.instagram {
        background: url(../../../assets/img/mainScreen/instaGroupDarkFooter.svg) center/contain no-repeat;
      }
    }
  }

  .footer_item_dialog {
    background: url(../../../assets/img/mainScreen/dialogDarkFooter.svg) center center no-repeat;
  }
}

.footer.white.mki {
  .footer_item {
    p {
      color: #564656;

      a {
        color: #564656;
      }
    }
  }
}

.chatbot_open {
  margin-top: -175px;
}

@keyframes line_horizontal {
  from {
    width: 0%;
    opacity: 1;
  }
  20% {
    width: 0%;
    opacity: 1;
  }
  40% {
    width: 100%;
    opacity: 1;
  }
  to {
    width: 100%;
    opacity: 0;
  }
}

@keyframes fill_horizontal {
  from {
    width: 100%;
    opacity: 1;
  }
  60% {
    width: 100%;
    opacity: 1;
  }
  to {
    width: 0%;
  }
}
