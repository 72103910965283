.contactsWrapper {
  width: 100%;
  height: calc(100% - 80px);
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  &_col {
    height: 100%;
    &_first {
      width: 50%;
      display: flex;
      overflow-y: auto;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      position: relative;
      padding: 132px 48px 50px 45px;
      scrollbar-width: none;
      &::-webkit-scrollbar{
        width: 0px;
      }

      @media screen and (max-width: 1200px) {
        width: 100%;
        padding: 40px 0px 0px 19px;
      }
      .logo {
        position: absolute;
        left: 48px;
        top: 29px;
        width: 127px;
        height: 64px;
        background: url('../../../assets/img/contacts/logo.svg') center/100% no-repeat;
        transition: opacity .5s;
        &:hover {
          opacity: .5;
        }
      }
      .header {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 64px;
        line-height: 100%;
        color: #000000;
        margin-bottom: 35px;
        @media screen and (max-width: 1200px) {
          font-size: 45px;
          font-weight: 400;
          color: #5A5863;
        }
        @media screen and (max-height: 1045px) {
          margin-bottom: 5px;
        }
      }
      .tabs-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .tabs-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: max-content;
          margin-bottom: 55px;
          @media screen and (max-width: 1200px) {
            flex-direction: column;
            width: 93%;
          }
          @media screen and (max-height: 1045px) {
            margin-bottom: 30px;
          }
          .tab {
            width: 33%;
            min-width: 180px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 120%;
            display: flex;
            align-items: center;
            color: #7F8190;
            text-align: left;
            position: relative;
            cursor: pointer;
            @media screen and (max-width: 1200px) {
              line-height: 18px;
              padding-bottom: 2px;
              width: 100%;
              margin-top: 20px;
            }
            &::after {
              content: '';
              position: absolute;
              width: 90%;
              height: 2px;
              left: 0;
              bottom: -5px;
              background-color: rgba(148, 151, 170, 0.22);
              @media screen and (max-width: 1200px) {
                width: 100%;
              }
            }
            &.active {
              font-weight: 700;
              &::after {
                background-color: red;
              }
            }
          }
        }
        .tab-content {
          display: none;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          height: 100%;
          width: 100%;
          &.active {
            display: flex;
          }
          .nums {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 73px;
            width: 100%;
            margin-top: 40px;
            &.vacancies {
              flex-wrap: nowrap;
            }
            @media screen and (max-width: 1200px) {
              margin-top: 75px;
              flex-direction: column;
              margin-bottom: 0;
            }
            @media screen and (max-height: 1045px) {
              margin-bottom: 30px;
            }
            .item {
              width: 50%;
              // min-width: ;
              display: flex;
              flex-direction: column;
              // justify-content: space-between;
              align-items: flex-start;
              margin-bottom: 55px;
              position: relative;
              padding-right: 15px;
              @media screen and (max-width: 1200px) {
                width: 100%;
              }
              &.vacancy {
                width: 100%;
                position: relative;
                @media screen and (max-width: 1200px) {
                  margin-top: 25px;
                  margin-bottom: 0;
                }
                .name {
                  font-size: 22px;
                  line-height: 120%;
                  display: flex;
                  align-items: center;
                  color: #7F8190;
                }
                .site-name {
                  font-size: 30px;
                  line-height: 120%;
                  font-weight: 500;
                  color: #FFF;
                  text-align: center;
                  background-color: #E1251B;
                  padding: 21px 0;
                  width: 100%;
                  margin-bottom: 18px;
                }
                .info {
                  position: absolute;
                  bottom: -120px;
                  font-weight: 300;
                  font-size: 15px;
                  width: 65%;
                  display: block;
                  line-height: 120%;
                  align-items: center;
                  color: #E1251B;
                  @media screen and (max-width: 1200px) {
                    max-width: 500px;
                    position: relative;
                    bottom: 0;
                  }
                }
              }
              @media screen and (max-height: 1045px) {
                margin-bottom: 20px;
              }
              .name {
                font-family: 'Roboto', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 22px;
                line-height: 120%;
                display: flex;
                align-items: center;
                color: #7F8190;
                margin-bottom: 10px;
                @media screen and (max-width: 1200px) {
                  font-size: 22px;
                  margin-bottom: 2px;
                }
                &.hot-line {
                  color: #E1251B;
                }
                &.international {
                  color: transparent;
                }
              }
              .info {
                font-size: 38px;
                line-height: 120%;
                display: flex;
                align-items: center;
                color: #000000;
                @media screen and (max-width: 1770px) {
                  font-size: 36px;
                }
                @media screen and (max-width: 1690px) {
                  font-size: 32px;
                }
                @media screen and (max-width: 1600px) {
                  font-size: 30px;
                }
                @media screen and (max-width: 1360px) {
                  font-size: 27px;
                }
                @media screen and (max-width: 1200px) {
                  font-size: 30px;
                }
                &.email {
                  color: #E1251B;
                  font-size: 29px;
                  font-weight: 400;
                  @media screen and (max-width: 1345px) {
                    font-size: 26px;
                  }
                  @media screen and (max-width: 1200px) {
                    font-size: 20px;
                  }
                }
              }
              .tip {
                font-size: 15px;
                line-height: 120%;
                display: flex;
                align-items: center;
                color: #7F8190;
                position: absolute;
                bottom: -20px;
                left: 0px;
                @media screen and (min-width: 1200px) {
                  bottom: -38px;
                }
                @media screen and (min-width: 1360px) {
                  bottom: -20px;
                }
              }
            }
            .item_hot-line{
            margin-top: 25px;
            }
            .thirst{
              margin-bottom: 35px;
            }
            .international{
              @media screen and (max-width: 1200px) {
                margin: 0;
                margin-top: 15px;
              }
            }
            .vostok{
              @media screen and (max-width: 1200px) {
                margin-top: 30px;
              }
            }
          }
          .nums .item:last-child{
            @media screen and (max-width: 1200px) {
              margin-top: 40px;
              margin-bottom: 20px;
            }
          }
          .address {
            width: 100%;
            position: relative;
            font-size: 22px;
            line-height: 160%;
            display: flex;
            align-items: center;
            color: #7F8190;
            padding-left: 30px;
            margin-bottom: 55px;
            @media screen and (max-height: 1045px) {
              margin-bottom: 26px;
            }
            &::before {
              position: absolute;
              content: '';
              left: 0;
              bottom: 20%;
              width: 18px;
              height: 25px;
              background: url('../../../assets/img/contacts/baloon-icon.svg') center/100% no-repeat;
            }
          }
          .write-letter {
            width: 191px;
            height: 60px;
            background: #E1251B;
            font-weight: 500;
            font-size: 15px;
            line-height: 60px;
            text-align: center;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }
    }
    &_second {
      width: 50%;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      background-image: url('../../../assets/img/contacts/right-column-background.jpg');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      @media screen and (max-width: 1200px) {
        width: 100%;
        height: 319px;
      }
      .background-wrapper{
        position: absolute;
        height: 100%;
        width: 50%;
        background: rgba(234, 234, 234, 0.41);
        mix-blend-mode: normal;
        @media screen and (max-width: 1200px){
          height: 319px;
          width: 100%;
        }
      }
      .logo {
        width: 490px;
        height: 250px;
        background: url('../../../assets/img/contacts/logo.svg') center/100% no-repeat;
        cursor: pointer;
        z-index: 99;
        @media screen and (max-width: 1200px) {
          background-size: 200px;
        }
      }
    }
    .b-retail {
        font-size: 22px;
        line-height: 120%;
        width: max-content !important;
        display: flex;
        flex-direction: column;
        align-items: start;
        color: #7F8190;
        gap: 12px;

        .button-pdf {
            color: #f0f0f0;
            padding: 8px 10px;
            background-color: #E1251B;
            border-radius: 4px;
            transition: opacity 450ms ease;
        }
        .button-pdf:hover {
            opacity: 0.65;
        }
    }
  }
}



