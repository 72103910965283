.swiperContainer4 {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  max-width: 100%;
  // align-items: stretch;
  box-sizing: border-box;
  .mainWrapper_col_4_ready_slider {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    height: 100%;
    background-color: #45494E;
    padding: 15px 20px 30px;
    box-sizing: border-box;
    background: #F1F1F1;
    .slide {
      display: block;
      width: 100%;
      height: 100%;
    }
    .slide-1 {
      background: url(../../../../assets/img/mainScreen/ready/1_naggetsy.webp) center/contain no-repeat;
    }
    .slide-2 {
      background: url(../../../../assets/img/mainScreen/ready/2_naggetsy_cheese.webp) center/contain no-repeat;
    }
    .slide-3 {
      background: url(../../../../assets/img/mainScreen/ready/3_popcorn.webp) center/contain no-repeat;
    }
    .slide-4{
      background: url(../../../../assets/img/mainScreen/ready/4_stripsy.webp) center/contain no-repeat;
    }
  }
  .swiper-pagination-bullet {
    background-color: #FFFFFF;
  }
  .swiper-pagination-bullet-active {
    background-color: #FF483F;
  }
  .swiper-pagination {
    text-align: center;
    padding-left: 15px;
    box-sizing: border-box;
  }
}
