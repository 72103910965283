.swiperContainer2 {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  .mainWrapper_col_second_meal-for-brain_slider {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    padding: 70px 29px 30px 30px;
    box-sizing: border-box;
    background-color: #E1251B;
    font-size: 1vw;
    @media screen and (max-width: 1200px) {
      padding: 76px 12px 0px 19px;
      background-color: #5A5863;
    }
    .header {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: .9em;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-bottom: 10px;
      @media screen and (max-width: 1200px) {
        font-size: 14px;
      }
    }
    .text_content {
      .subheader {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: .8em;
        line-height: 120%;
        color: #fff;
        @media screen and (max-width: 1200px) {
          display: none;
        }
      }
      .text {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 120%;
        color: rgba(255, 255, 255, 0.6);
        height: 92px;
        overflow: hidden;
      }
    }
  }
  .swiper-pagination-bullet {
    background-color: #FFFFFF;
  }
  .swiper-pagination-bullet-active {
    background-color: #FFFFFF;
    @media screen and (max-width: 1200px) {
      background-color: #E1251B;
    }
  }
  .swiper-pagination {
    text-align: left;
    padding-left: 27px;
    box-sizing: border-box;
    @media screen and (max-width: 1200px) {
      padding-left: 15px;
    }
  }
}
