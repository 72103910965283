@import "../../../assets/styles/_default/variables";
@import "../../../assets/styles/_mixins/breakpoint";

.petruhaGotovoWrapper {
  width: 100%;
  height: calc(100vh - 90px);
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  background-color: transparent;
  display: flex;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
  }

  @media screen and (min-width: 1200px) {
    // overflow-y: scroll;
  }

  @include breakpoint($tabletS__max) {
    height: auto;
    width: 70%;
    margin: 0 auto;
  }

  @include breakpoint($mobile__max) {
    width: 100%;
  }
  .logo {
    position: absolute;
    top: 93px;
    right: 40px;
    cursor: pointer;
    width: 167px;
    height: 89px;
    background: url("../../../assets/img/gotovo/gotovo-logo.svg") center/contain
      no-repeat;
    transition: opacity .5s;
    &:hover {
      opacity: .5;
    }
    @include breakpoint($tabletS__max) {
      top: 20px;
      right: auto;
      left: auto;
      width: 136px;
      height: 83px;
    }
    @include breakpoint($mobile__max) {
      left: 19px;
    }
  }
  &.petruhaBrandWrapper_transparent {
    background-color: transparent;
  }
  .brandsNavigation {
    display: flex;
    justify-content: space-between;
    width: 973px;
    align-items: center;
    top: 44px;
    right: 172px;
    height: 26px;
    position: absolute;
    @include breakpoint($tabletS__max) {
      display: none;
    }
    .brands-nav-item {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      margin-right: 40px;
      color: #ffffff;
      cursor: pointer;
      &:nth-child(3) {
        padding-bottom: 3px;
        border-bottom: 1px solid white;
      }
    }
  }
  &_header {
    margin-top: 15vh;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 100%;
    color: #ffffff;
    // margin-top: 128px;
    margin-left: 0;
    width: 1064px;
    word-wrap: normal;
    span {
      margin-left: 15px;
      color: rgb(193, 255, 91);
    }
    @include breakpoint($laptop__max) {
      font-size: 48px;
    }

    @include breakpoint($tabletS__max) {
      margin-top: 123px;
      font-weight: normal;
      font-size: 22px;
      line-height: 109%;
      margin-left: 0;
    }

    @media screen and (max-width: 1200px) {
      margin-top: 30px;
      display: block;
      margin-left: 160px;
      width: 170px;
      padding: 20px 25px;
    }
  }

  &_subheader {
    margin-top: 15px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    line-height: 100%;
    color: #c65dff;
    // margin-top: 128px;
    margin-left: 0;
    width: 1064px;
    word-wrap: normal;
    @include breakpoint($laptop__max) {
      font-size: 32px;
    }

    @include breakpoint($tabletS__max) {

      font-weight: normal;
      font-size: 20px;
      line-height: 109%;
      margin-left: 0;
    }

    @media screen and (max-width: 1200px) {
      margin-top: 30px;
      display: block;
      margin-left: 160px;
      width: 170px;
      padding: 20px 25px;
    }
  }

  &_content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: stretch;
    height: 669px;
    margin-top: 5vh;
    position: relative;
    z-index: 5;
    //padding-top: 65px;
    //overflow: hidden;
    @include breakpoint($tabletS__max) {
      padding-top: 17px;
      flex-direction: column;
      height: auto;
      overflow-y: visible;
      margin-top: 0;
    }
    .first-col {
      width: 29%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      cursor: pointer;
      position: relative;
      padding-left: 60px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0px 40px 30px 60px;
      @include breakpoint($tabletS__max) {
        width: 100%;
        height: 340px;
        padding: 19px;
        // padding: 15px 19px 15px;
      }
      .items-text {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
      .items-text::after {
        content: "";
        text-align: right;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
        background: rgba(46, 9, 62, .3);
        pointer-events: none;
      }
      .play-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        height: auto;
        .play-icon {
          width: 77px;
          height: 77px;
          background: url("../../../assets/img/petruhaBrandContent/playIcon.png")
            center center no-repeat;
          margin-bottom: 30px;
          position: relative;
          bottom: 20px;
          left: 40px;
          transform: scale(1) translate(-50%, 50%);
          // pointer-events: none;
          &:hover {
            animation: box-shadow-hover 2s ease infinite;
            // transition: all ease 2s;
            border-radius: 50px;
          }
        }
        .play-description {
          width: 100%;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 144%;
          color: #ffffff;
          pointer-events: none;
          word-wrap: break-word;
          @include breakpoint($tabletS__max) {
            font-size: 16px;
          }
        }
      }
    }
    .second-col {
      width: 71%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      @include breakpoint($tabletS__max) {
        width: 100%;
      }
      .top {
        height: 39%;
        background: linear-gradient(
          90deg,
          #c77fd7, 16.89%,
          #b763cd 106.48%
        );
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @include breakpoint($tabletS__max) {
          // position: relative;
          display: unset;
        }
        .descr {
          width: 45%;
          height: 100%;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          padding-left: 29px;
          padding-top: 20px;
          font-size: 18px;
          line-height: 130%;
          color: #ffffffd6;
          white-space: pre-line;
          @media screen and (max-width: 1330px) {
            font-size: 16px;
          }
          @media screen and (max-height: 850px) {
            font-size: 14px;
          }
          @include breakpoint($tabletS__max) {
            height: auto;
            width: 100%;
            padding: 20px;
            padding-bottom: 15px;
            font-size: 14px;
            line-height: 130%;
            font-weight: 400;
          }
        }
        .picture {
          width: 55%;
          height: 100%;
          position: relative;
          @include breakpoint($tabletS__max) {
            position: unset;
            width: 100%;
            bottom: 0;
          }
          .squinting-picture {
            position: absolute;
            width: 130%;
            height: 560px;
            left: -35%;
            bottom: 0;
            background-size: contain;
            background-position: left bottom;
            background-repeat: no-repeat;
            z-index: 2;
            pointer-events: none;
            @include breakpoint($tabletS__max) {
              min-height: 100px;
              height: 40vw;
              width: 100%;
              position: unset;
              background-size: cover;
              background-position: -100px center;
            }
            @include breakpoint($mobileS__max) {
              width: 100%;
              background-position: -105px 0;
            }
            @include breakpoint($mobile__max) {
              min-height: 100px;
              height: 60vw;
              width: 150%;
              position: unset;
              background-size: cover;
              background-position: -115px -5px;
            }
          }
        }
      }
      .bottom {
        height: 61%;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: stretch;
        @include breakpoint($tabletS__max) {
          display: unset;
        }
        .flag {
          position: absolute;
          width: 323px;
          height: 77px;
          left: 0px;
          top: 0px;
          background: #ffffff;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          color: #2a384c;
          @include breakpoint($tabletS__max) {
            width: 100%;
          }
        }
        .assorti-swiper-container {
          width: 42%;
          height: 100%;
          overflow: hidden;
          background-color: #af5fc1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 77px 73px 49px 29px;
          @include breakpoint($tabletS__max) {
            width: 100%;
            padding: 77px 0 0 0;
          }
          .text {
            font-size: 21px;
            line-height: 141.5%;
            color: #ffffff;
            @include breakpoint($tabletS__max) {
              font-size: 14px;
              padding: 18px 19px 19px 19px;
              font-weight: normal;
            }
            @media screen and (max-height: 840px) {
              font-size: 16px;
            }
            @media screen and (max-height: 740px) {
              font-size: 14px;
            }
          }
          .swiper-pagination-bullets {
            text-align: left;
            padding-left: 60px;
            .swiper-pagination-bullet {
              background-color: red;
              opacity: 1;
              &.swiper-pagination-bullet-active {
                background-color: white;
              }
            }
          }
        }
        .tasty-dish {
          width: 58%;
          height: 100%;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }
        .cover {
          width: 100%;
          height: 434px;
          background-size: cover;
          background-position: center center;
          cursor: pointer;
        }
      }
    }
  }
  .videoPopup {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #000000bb;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
