// Colors
$white: #fff;
$black: #000;
$blue: #1a93da;
$red: #e63000;
$darkRed:  #e92e00;
$darkerRed:  #f41414;
$darkestRed:  #e33219;
$orange: #ff9107;
$gray: #414141;
$lightGray: #ededed;
$lightGrayHover: rgba(237, 237, 237, 0.85);
$lightestGray: #fafafa;
$dark: #000a29;
$brown: #5a1404;
$darkBrown: #5b1A0d;
$yellow: #ffcd00;
$lightYellow: #ffcd01;
$darkYellow: #fecc2f;
$darkerYellow: #ecb200;
$violet: violet;

$color-main: $brown;
$color-main--dark: $darkBrown;
$color-important: $red;
$color-important--dark: $darkRed;
$color-important--darker: $darkerRed;
$color-important--darkest: $darkestRed;
$color-secondary: $yellow;
$color-secondary--light: $lightYellow;
$color-secondary--dark: $darkYellow;
$color-secondary--darker: $darkerYellow;
$color-light: $white;

$font-color__main: $color-main;
$font-color__main--dark: $color-main--dark;
$font-color__main--light: $color-light;
$font-color__important: $color-important;
$font-color__important--darker: $color-important--darker;

$bg-color__main: $color-secondary;
$bg-color__main--light: $color-secondary--light;
$bg-color__main--dark: $color-secondary--dark;
$bg-color__main--darker: $color-secondary--darker;
$bg-color__secondary: $color-light;
$bg-color__important: $color-important;
$bg-color__important--dark: $color-important--darkest;

$border-color__main: $color-important--darker;
$border-color__secondary: $color-important--darker;

$linear-gradient__main: linear-gradient(180deg, #ecb200 0%, #ffcf00 78.83%);
$linear-gradient__secondary: linear-gradient(180.03deg, #ededed 22.63%, #ffffff 99.95%);;

// Font
$font-family__default: 'PF Din Text Cond Pro', 'Arial', sans-serif;

// Breakpoints
$desktopM: 2560px;
$desktopS: 1920px;
$desktop: 1680px;
$laptopS: 1440px;
$laptop: 1200px;
$tabletS: 1024px;
$tablet: 768px;
$mobileS: 480px;
$mobile: 320px;

$desktopS__max: max-width 2559px;
$desktop__max: max-width 1919px;
$laptopS__max: max-width 1679px;
$laptop__max: max-width 1439px;
$tabletS__max: max-width 1199px;
$tablet__max: max-width 1023px;
$mobileS__max: max-width 767px;
$mobile__max: max-width 479px;

// Retina
$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;