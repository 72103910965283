.swiperContainer {
  position: relative;
  height: 50%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  #secondColNewsSwiper .swiper-pagination-bullets {
    bottom: 20px;
  }

  .mainWrapper_col_second_online_slider {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    background-color: #45494e;
    padding: 76px 30px 63px;
    box-sizing: border-box;
    font-size: 1vw;
    @media screen and (max-height: 1200px) {
      padding: 56px 20px 50px 20px;
      display: block;
    }
    .book-icon {
      min-height: 35px;
      width: 57px;
      height: 47px;
      background: url(../../../../assets/img/mainScreen/book.gif) center/cover no-repeat;
      margin-left: -4px;
      @media screen and (max-height: 780px) {
        display: none;
      }
    }
    .header {
      @media screen and (max-width: 1200px) {
        font-size: 14px;
        line-height: 18px;
      }
      // @media screen and (max-height: 850px) {
      //   font-size: 13px;
      //   margin-bottom: 8px;
      // }
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 0.9em;
      text-transform: uppercase;
      color: #ffffff;
    }
    .text_content {
      font-size: 0.8em;
      .subheader {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        // height: 40px;
        margin-top: 10px;
        margin-bottom: 5px;
        overflow: hidden;
        color: rgba(255, 255, 255, 0.6);
        @media screen and (max-width: 1200px) {
          font-size: 14px;
          width: 100%;
          line-height: 17px;
        }
        @media screen and (max-width: 370px) {
          font-size: 12px;
          width: 100%;
          line-height: 17px;
        }
      }
      .text {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 120%;
        color: rgba(255, 255, 255, 0.6);
        // height: 3.5em;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        -ms-line-clamp: 4;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        display: -webkit-box;
        // display: box;
        word-wrap: break-word;
        -webkit-box-orient: vertical;
        box-orient: vertical;
        @media screen and (max-width: 1200px) {
          font-size: 14px;
          width: 90%;
          line-height: 17px;
        }
        @media screen and (max-width: 370px) {
          font-size: 12px;
          width: 100%;
          line-height: 17px;
        }
      }
    }
  }
  .swiper-pagination-bullet {
    background-color: #ffffff;
  }
  .swiper-pagination-bullet-active {
    background-color: #ff483f;
  }
  .swiper-pagination {
    text-align: left;
    padding-left: 16px;
    box-sizing: border-box;
    @media screen and (max-width: 1200px) {
      padding-left: 15px;
    }
  }
}
