.petruhaOnlineWrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  background-color: #ffffff;
  padding-top: 132px;
  &_logo {
    display: block;
    position: absolute;
    top: 20px;
    left: 48px;
    width: 127px;
    height: 64px;
    background: url("../../../assets/img/petruhaOnline/logo.svg") center center no-repeat;
    cursor: pointer;
    transition: opacity .5s;
    &:hover {
      opacity: .5;
    }
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  &_how {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding-left: 45px;
    position: relative;
    @media screen and (max-width: 1200px) {
      padding: 0 20px;
    }
    .content {
      display: flex;
      // justify-content: space-between;
      margin-bottom: 25px;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
      }
    }
    .header {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 42px;
      line-height: 100%;
      color: #000000;
      //transform: translateX(-126px);
      margin-bottom: 102px;
      @media screen and (max-width: 1200px) {
        width: 100%;
        transform: none;
        margin-top: 20px;
        margin-bottom: 24px;
        font-size: 45px;
      }
    }
    .text {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 24px;
      line-height: 36px;
      color: #000000;
      @media screen and (max-width: 1200px) {
        font-size: 14px !important;
        line-height: 21px !important;
      }
      @media screen and (min-width: 1920px) {
        //padding-left: 126px;
      }
    }
    .text-content {
      width: 40%;
      height: auto;
      //padding-left: 126px;
      @media screen and (max-width: 1200px) {
        width: 100%;
        padding: 0;
      }
    }
    .image {
      width: 100%;
      height: calc(60vw / 850 * 478 + 42px);
      margin-left: 42px;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      margin-right: 40px;
      @media screen and (max-width: 1200px) {
        margin-left: 0;
        width: 100%;
        height: calc(100vw / 850 * 478 - 40px);
        background-position: center;
        margin-top: 21px;
        //max-height: 350px;
      }
      .play-icon {
        position: absolute;
        display: block;
        margin: 0 auto;
        bottom: 50%;
        left: 50%;
        transform: scale(1) translate(-50%, 50%);
        width: 58px;
        height: 58px;
        cursor: pointer;
        background: url('../../../assets/img/petruhaOnline/play-icon.png') center center no-repeat;
        // pointer-events: none;

        &:hover {
          animation: box-shadow-hover 2s ease infinite;
          border-radius: 50px;
        }

      }
    }
    .how-swiper-wrapper {
      width: 100%;
      height: 340px;
      margin-bottom: 50px;
      padding-left: 32px;
      @media screen and (max-width: 1200px) {
        padding-left: 0px;
        height: auto;
        margin-bottom: 0px;
      }
      .interviewSwiper {
        padding-left: 15px;
        @media screen and (max-width: 1200px) {
          padding-left: 0;
          margin-left: 0 !important;
          padding-bottom: 55px;
        }
      }

      .swiper-pagination{
        text-align: left;
      }

      .swiper-pagination-bullets{
        bottom: 32px;
      }

      .swiper-pagination-bullet-active{
        background-color: #E1251B;
      }

      .how-slide-wrapper {
        width: 263px;
        height: 300px;
        background: #FFFFFF;
        border-radius: 13px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 21px;
        margin: 30px 0px;
        margin-right: 0px;
        cursor: pointer;
        @media screen and (max-width: 1200px) {
          height: auto;
          margin: 0;
          width: 100%;
          padding: 0;
        }
        &.current-slide {
          box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.1);
          @media screen and (max-width: 1200px) {
            box-shadow: none;
          }
        }
        .icon {
          width: 222px;
          height: 125px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          margin: 0 auto;
          margin-bottom: 17px;
        }
        .header {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 120%;
          color: #000000;
          margin-bottom: 3px;
          text-align: left;
          height: 55px;
          overflow: hidden;
          padding: 5px;
          text-overflow: ellipsis;
          transform: translate(0px);
          width: auto;
          @media screen and (max-width: 1200px) {
            height: auto;
          }
        }
        .text {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 120%;
          color: #585858;
          height: 83px;
          overflow: hidden;
          padding: 5px;
          text-overflow: ellipsis;
          @media screen and (max-width: 1200px) {
            overflow: unset;
            height: auto;
          }
        }
      }
    }
  }
  &_interview {
    width: 100%;
    height: 689px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #393F45;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 1200px) {
      padding: 0 20px;
      // padding-bottom: 80px;
      height: auto;
    }

    .interviewSwiper{
      @media screen and (max-width: 1200px) {
        padding-bottom: 80px;
      }
    }

    .swiper-pagination-bullet{
      background-color: rgba($color: #FFF, $alpha: .26);
    }

    .swiper-pagination-bullet-active{
      background-color: #E1251B;
    }

    .swiper-pagination{
      bottom: 40px !important;
      text-align: left;
    }

    .header {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 64px;
      line-height: 167px;
      color: #FFFFFF;
      margin-left: 45px;
      @media screen and (max-width: 1200px) {
        line-height: 104px;
        font-size: 24px;
        margin-left: 0;
      }
    }
    .interview-swiper-container {
      padding-left: 171px;
      width: 100%;
      height: 800px;
      @media screen and (max-width: 1200px) {
        padding-left: 0;
        height: auto;
        // padding-bottom: 80px;
      }
      .interview-slide-wrapper {
        display: flex;
        width: 1200px;
        margin-right: 100px;
        @media screen and (max-width: 1200px) {
          width: 100%;
          margin: 0;
          flex-direction: column;
        }
        .icon {
          height: 478px;
          width: 60%;
          position: relative;
          background-size: cover;
          @media screen and (max-width: 1200px) {
            width: 100%;
            max-height: calc(100vw / 600 * 400 - 40px);
            background-size: cover;
            background-repeat: no-repeat;
          }
          .play-icon {
            position: absolute;
            display: block;
            bottom: 50%;
            left: 50%;
            transform: scale(1) translate(-50%, 50%);
            cursor: pointer;
            margin: 0 auto;
            // transform: translate(-50%, -50%);
            width: 58px;
            height: 58px;
            background: url('../../../assets/img/petruhaOnline/play-icon.png') center center no-repeat;
            // pointer-events: none;

            &:hover {
              animation: box-shadow-hover 2s ease infinite;
              // position: absolute;
              // transition: all ease 2s;
              border-radius: 50px;
            }

          }
        }
        .fio{
          display: flex;
          margin-top: 20px;
        }
        .name {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 48px;
          line-height: 100%;
          text-align: right;
          color: #FFFFFF;
          position: absolute;
          right: 10px;
          bottom: 50px;
          word-wrap: break-word;
          &.first {
            bottom: 90px;
            margin-right: 7px;
          }
          @media screen and (max-width: 1200px) {
            position: unset;
            text-align: left;
            font-size: 20px;
            font-weight: 700;
          }
        }
        .position {
          font-family: 'Roboto', sans-serif;
          font-style: italic;
          font-weight: normal;
          font-size: 18px;
          line-height: 100%;
          text-align: right;
          color: #FFFFFF;
          position: absolute;
          right: 15px;
          bottom: 20px;
          @media screen and (max-width: 1200px) {
            position: unset;
            text-align: left;
            font-size: 18px;
            margin-top: 6px;

          }
        }
        .text-wrapper {
          width: 272px;
          padding-top: 60px;
          padding-left: 20px;
          position: relative;
          @media screen and (max-width: 1200px) {
            padding: 0;
            padding-top: 0px;
            width: 100%;
            margin-top: 25px;
          }
          .quotes {
            position: absolute;
            top: 0;
            left: 20px;
            width: 49px;
            height: 36px;
            background-image: url('../../../assets/img/petruhaOnline/quates.png');
            z-index: 2;
            @media screen and (max-width: 1200px) {
              left: 0;
            }
          }
          .quotes_bottom {
            position: absolute;
            transform: rotate(180deg);
            top: auto;
            right: 0;
            width: 49px;
            height: 36px;
            background-image: url('../../../assets/img/petruhaOnline/quates.png');
            z-index: 2;
            margin-top: 20px;
            @media screen and (max-width: 1200px) {
              // left: 0;
            }
          }
          .text {
            position: relative;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: #FFFFFF;
            // text-align: justify;
          }
          .interview-link {
            display: inline-block;
            width: 100%;
            max-width: 252px;
            height: 60px;
            text-align: center;
            border: 2px solid #999ea2;
            border-radius: 30px;
            position: absolute;
            bottom: 0px;
            color: #999ea2;
            font-size: 18px;
            padding: 18px 0px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            @media screen and (max-width: 1200px) {
              position: relative;
              margin-top: 20px;
              max-width: 100%;
            }
          }
          .interview-link:hover {
            color: #FFFFFF;
            border: 2px solid #FFFFFF;
          }
        }
      }
    }
  }
  &_experts {
    width: 100%;
    height: 874px;
    position: relative;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 45px;
    @media screen and (max-width: 1200px) {
      padding: 0 20px;
      height: 465px;
    }
    .header {
      line-height: 140px;
      text-align: left;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 64px;
      color: #000000;
      @media screen and (max-width: 1200px) {
        font-size: 24px;
        line-height: 72px;
        margin-top: 10px;
      }
    }
    .content-wrapper {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      position: relative;
      padding-left: 126px;
      @media screen and (max-width: 1200px) {
        padding-left: 0;
        padding-bottom: 80px;
        max-width: 350px;
        margin: 0 auto;
      }
      .list {
        position: absolute;
        width: 126px;
        left: 0;
        top: 0;
        height: max-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .item {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 330%;
          color: red;
          position: relative;
          cursor: pointer;
          &.clicked {
            color: #5A5863;
          }
          &:after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 25px;
            height: 1px;
            background-color: #C0C5E4;
          }
          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }
      .experts-swiper-wrapper {
        width: 100%;
        height: 617px;
        @media screen and (max-width: 1200px) {
          height: 297px;
        }

        .expertsSwiper{
          @media screen and (max-width: 1200px) {
            height: 100%;
            padding-bottom: 80px;
            box-sizing: content-box;
          }
        }

        .swiper-pagination-bullet-active{
          background-color: #E1251B;
        }

        .swiper-pagination{
          bottom: 40px !important;
          text-align: left;
        }

        .swiper-slide-wrapper {
          width: 425px;
          height: 617px;
          position: relative;
          @media screen and (max-width: 1200px) {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center 0;
          }

          .swiper-container{
            @media screen and (max-width: 1200px) {
              height: 100%;
            }
          }

          .expert-link{
            display: block;
            height: 100%;
          }

          .firstName, .secondName {
            position: absolute;
            left: 15px;
            bottom: 100px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 36px;
            line-height: 100%;
            color: #FFFFFF;
            @media screen and (max-width: 1200px) {
              font-size: 20px;
              bottom: 75px;
            }
          }
          .secondName {
            bottom: 70px;
            @media screen and (max-width: 1200px) {
              font-size: 20px;
              bottom: 55px;
            }
          }
          .position {
            position: absolute;
            bottom: 25px;
            left: 15px;
            font-family: 'Roboto', sans-serif;
            font-style: italic;
            font-weight: normal;
            font-size: 18px;
            line-height: 100%;
            color: #FFFFFF;
            @media screen and (max-width: 1200px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .videoPopup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000bb;
    z-index: 15;
    display: flex;
    justify-content: center;
    align-items: center;
    iframe {
      max-width: 100%;
      min-height: 200px;
      @media screen and(max-width: 560px) {
        max-width: 100%;
      }
    }
    .empty-video {
      position: absolute;
      color: #ffffff;
      font-size: 28px;
      text-align: center;
    }
  }
}

.how-swiper-wrapper{
  padding-left:  20px;
  .swiper-slide {
    // margin-left: -100px;
    display: flex;
    justify-content: center;
    // width: 262px !important;
  }
}

.petruhaOnlineWrapper_how .how-swiper-wrapper .interviewSwiper{
  margin-left: 50px;
}

