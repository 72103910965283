* {
	padding: 0;
	margin: 0;
}
html {
	font-size: 1em;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
}
body {
	font-size: 100%;
}
a img, :link img, :visited img {
	border: 0;
}
a {
	text-decoration: none;
}
* {
	box-sizing: border-box;
}
:active, :hover, :focus {
	outline: 0;
	outline-offset: 0;
}
* {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently not supported by any browser */
}
