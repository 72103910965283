@import '../../../assets/styles/_default/variables';
@import '../../../assets/styles/_mixins/breakpoint';

.petruhaMKIWrapper {
  width: 100%;
  height: calc(100vh - 90px);
  // overflow-y: scroll;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  scrollbar-width: none;
  display: flex;
  &::-webkit-scrollbar{
    width: 0px;
  }

  @include breakpoint($tabletS__max) {
    height: auto;
    width: 70%;
    margin: 0 auto;
  }

  @include breakpoint($mobile__max) {
    width: 100%;
  }
  .logo {
    position: absolute;
    top: 114px;
    right: 40px;
    cursor: pointer;
    width: 211px;
    height: 102px;
    background: url(../../../assets/img/mainScreen/sausages.svg) center/cover no-repeat;
    background-size: contain;
    transition: opacity .5s;
    &:hover {
      opacity: .5;
    }
    @include breakpoint($tabletS__max) {
      top: 20px;
      right: auto;
      left: auto;
      width: 136px;
      height: 83px;
    }

    @include breakpoint($mobile__max) {
      left: 19px;
    }
  }
  &.petruhaMKIWrapper_transparent {
    background-color: transparent;
  }
  .brandsNavigation {
    display: flex;
    justify-content: flex-start;
    width: 973px;
    align-items: center;
    top: 44px;
    right: 172px;
    height: 26px;
    position: absolute;

    @include breakpoint($tabletS__max) {
      display: none;
    }
    .brands-nav-item {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      margin-right: 40px;
      color: #45494E;
      cursor: pointer;
      &:nth-child(4) {
        padding-bottom: 3px;
        border-bottom: 1px solid black;
      }
    }
  }
  &_header {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 100%;
    color: #E1251B;
    margin-top: 15vh;
    margin-left: 0;
    width: 1064px;
    word-wrap: normal;
    span {
      color: #564656;
    }

    @include breakpoint($laptop__max) {
      font-size: 48px;
    }

    @include breakpoint($tabletS__max) {
      margin-top: 123px;
      font-weight: normal;
      font-size: 22px;
      line-height: 109%;
      margin-left: 0;
    }

    @media screen and (max-width: 1200px) {
      margin-top: 17px;
      display: block;
      margin-left: 160px;
      width: 170px;
      padding: 20px 25px;
    }
  }

  &_content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 740px;
    padding-top: 65px;
    overflow: hidden;

    @include breakpoint($tabletS__max) {
      padding-top: 17px;
      flex-direction: column-reverse;
      height: auto;
      overflow-y: visible;
    }

    .first-col {
      width: 30%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0px 40px 30px 60px;

      @include breakpoint($tabletS__max) {
        min-height: 339px;
        width: 100%;
        padding: 15px 19px 15px;
      }
      .play-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        height: auto;
        .play-icon {
          width: 77px;
          height: 77px;
          background: url('../../../assets/img/petruhaBrandContent/playIcon.png') center center no-repeat;
          margin-bottom: 30px;
          position: relative;
          bottom: 20px;
          left: 40px;
          transform: scale(1) translate(-50%, 50%);
          // pointer-events: none;

          @include breakpoint($tabletS__max) {
            margin-bottom: 45px;
          }
          &:hover {
            animation: box-shadow-hover 2s ease infinite;
            // transition: all ease 2s;
            border-radius: 50px;
          }
        }
        .play-description {
          width: 100%;
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 144%;
          color: #FFFFFF;
          pointer-events: none;
          word-wrap: break-word;
          max-width: 335px;

          @include breakpoint($tabletS__max) {
            font-weight: normal;
            font-size: 16px;
            line-height: 151%;
          }
        }
      }
    }
    .second-col {
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      @include breakpoint($tabletS__max) {
        width: 100%;
      }
      .top {
        height: 60%;
        width: 100%;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;

        @include breakpoint($tabletS__max) {
          height: auto;
        }
        .top-left {
          width: 44%;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          @include breakpoint($tabletS__max) {
            width: 100%;
          }
          .descr {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 20px;
            padding-left: 27px;
            height: 50%;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-size: 16px;
            line-height: 150%;
            color: #564656;
            background-color: #EFEFEF;

            @media screen and (max-height: 800px) {
              font-size: 16px;
            }

            // p {
            //   max-width: 397px;
            // }

            @include breakpoint($tabletS__max) {
              height: auto;
              padding-left: 20px;
              font-size: 14px;
              line-height: 130%;

              p {
                max-width: 100%;
              }
            }
          }
          .mki-swiper-container {
            height: 50%;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 32px;
            line-height: 120%;
            color: #000000;

            @include breakpoint($tabletS__max) {
              height: auto;
              min-height: 136px;
              display: flex;
              padding: 20px 20px 5px 20px;
              flex-direction: column;
              //justify-content: center;
              //align-items: center;
            }
            .swiper-container {
              width: 100%;
              height: 100%;

              @include breakpoint($tabletS__max) {
                padding-bottom: 46px;
              }
              .mki-swiper-content {
                width: 100%;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 20px;
                padding-right: 140px;

                @media screen and (max-height: 800px) {
                  transform: scale(0.8) translate(0, -10px);
                }

                @include breakpoint($desktop__max) {
                  padding-right: 30px;
                }

                @include breakpoint($laptopS__max) {
                  padding-right: 10px;
                }

                @include breakpoint($tabletS__max) {
                  padding-left: 0;
                  justify-content: flex-start;
                }

                .icon {
                  background-position:  center;
                  background-size: cover;
                  background-repeat: no-repeat;
                  left: 50px;
                  width: 90px;
                  height: 90px;
                  flex: 0 0 90px;
                  margin-right: 15px;

                  @include breakpoint($tabletS__max) {
                    width: 51px;
                    height: 51px;
                    flex: 0 0 44px;
                  }
                }
                .text {
                  font-weight: normal;
                  font-size: 17px;
                  line-height: normal;
                  color: #504750;

                  @include breakpoint($tabletS__max) {
                    font-size: 14px;
                    line-height: 130%;
                  }
                }
              }
              .swiper-pagination-bullets {
                text-align: left;
                padding-left: 20px;

                @include breakpoint($tabletS__max) {
                  padding-left: 0;
                  bottom: 0;
                }
                .swiper-pagination-bullet {
                  background-color: #E1251B;
                  opacity: 1;
                  &.swiper-pagination-bullet-active {
                    background-color: #D1D1D1;
                  }
                }
              }
            }
          }
        }
        .top-right {
          width: 56%;
          height: 100%;
          background-color: #efefef;
          position: relative;
          flex-grow: 0;
          flex-shrink: 0;

          @include breakpoint($tabletS__max) {
            display: none;
          }

          .swiper-container {
            width: 100%;
            height: 100%;
            .swiper-pagination-bullets {
              text-align: left;
              padding-left: 24px;
              .swiper-pagination-bullet {
                background-color: red;
                opacity: 1;
                &.swiper-pagination-bullet-active {
                  background-color: white;
                }
              }
            }
          }
          .cover {
            width: 100%;
            height: 100%;
            background-position: center center;
            cursor: pointer;
          }
          .image {
            position: absolute;
            width: 100%;
            height: 110%;
            bottom: 0;
            right: 0;
            background-position: left top;
            background-repeat: no-repeat;
            background-size: 615px 742px;
            z-index: 1;
            //@media screen and (max-height: 1080px) {
            //  background-size: auto 100%;
            //}
          }
        }
      }
      .bottom {
        height: 40%;
        width: 100%;
        position: relative;

        @include breakpoint($tabletS__max) {
          height: auto;
          min-height: 219px;
        }

        @include breakpoint($mobileS__max) {
          min-height: 296px;
        }
        .flag {
          position: absolute;
          width: 323px;
          height: 77px;
          left: 0px;
          top: 0px;
          background: #971811;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          color: #FFFFFF;

          @include breakpoint($tabletS__max) {
            top: auto;
            bottom: 0;
          }

          @include breakpoint($mobileS__max) {
            width: 100%;
          }
        }
        .cover {
          background-position: center center;
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          background-size: 103% auto;

          @include breakpoint($tabletS__max) {
            height: auto;
            min-height: 220px;
            background-size: cover;
          }
        }
      }
    }
  }
  .videoPopup {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #000000bb;
    z-index: 15;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}



