.page_main_footer_wrapper{
  @media screen and (max-width: 1199px) {
    overflow: unset !important;
  }
}


.petruhaOnlineWrapper{
  @media screen and (max-width: 1200px) {
    padding-top: 0;
  }
}



.wrapper__modal-window_expert{
    position: relative;
    bottom: 150px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1199px) {
        bottom: 60px;
        margin-bottom: -50px;
    }
}

.modal-window__background{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9;
    width: 100%;
    height: 2700px;
    background: black;
    opacity: 0.7;
    margin: 0 auto;
    @media screen and (max-width: 1199px) {
        // display: none;
    }
}

.main-expert_container{
    display: flex;
    justify-content: center;
    z-index: 10;
    width: 70vw;
    min-width: 1000px;
    position: absolute;
    top: 150px;
    margin: 0 auto;

    @media screen and (max-width: 1199px) {
      width: 100vw;
      min-width: unset;
      top: 18px;
      z-index: 100000;
      position: unset;
    }

    .b__container{
        width: 100%;
        background-color: white;

        .backLink{
          position: absolute;
          right: 30px;
          top: 30px;
          width: 30px;
          height: 30px;
          background: url('../../../assets/img/experts/closeIcon.png');
          background-repeat: no-repeat;
          background-position: center;
          a{
            width: 100%;
            height: 100%;
            display: block;
          }
        }
    }

    .container__header{
        padding: 50px;
        font-family: 'Roboto', sans-serif;
        @media screen and (max-width: 1199px) {
            padding: 25px 20px 20px 20px;
        }

        .header__about{
            @media screen and (max-width: 1199px) {
                font-size: 24px;
            }
            h1{
                font-weight: 300;
                font-size: 64px;
                line-height: 100px;
                line-height: 100%;
                color: black;
                @media screen and (max-width: 1199px) {
                    font-size: 24px;
                }
            }
            p{
                margin-top: 20px;
                color: #7F8190;
                font-size: 18px;
                font-style: italic;
                line-height: 100%;
                font-weight: normal;
            }
        }
        .header__message{
            padding: 80px 190px 50px 110px;
            font-weight: 300;
            font-size: 24px;
            line-height: 150%;
            @media screen and (max-width: 1199px) {
                font-size: 14px;
                padding: 0;
                padding-top: 20px;
            }
        }
    }

    .container__quote{
        display: flex;
        @media screen and (max-width: 1199px) {
            flex-direction: column-reverse;
        }
        .quote{
            width: 39%;
            background-color: #E1251B;
            color: white;
            @media screen and (max-width: 1199px) {
                width: 100%;
            }
            .quotes{
                height: 40px;
                width: 40px;
                background-size: 40px;
                margin-bottom: 20px;
                background-repeat: no-repeat;
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAAAkCAYAAAAgh9I0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGGSURBVHgB7ZfvUcMwDMUVrgNkAzxCN2g3gA1ghG7QbMAIzQaFCQIbsEHKBIUJhIzzIUf+6LnYqbnz706XD0mf/CQrcQu6EGYu5fIgsZbY2iiK4oMWQHJvu5wbiXfywS5c4l6i4SG3FJEudyXR/sp78BGxAmeeJoqJ3uKncusmbOtG3C9iQjR3SuF+TKwUkUoue1oYdvN2JLfvVVYzQjW5wV0UyWvk0kgY9Dc3E0I1+Rk4SXzRH7nEQJd7IFQxzlFiQwFgN8AtmNfOyb7bdgOhR1CkCbX4Xu4DmPtpdPGdiAErEXzQweKd1cIxto2ivKmA4tn7JoRQLANIF/StCwi1FAkeP8L0wY4VgFCU7wW7OdQwiFDJ1+uCtgOgLtiP3Vp55pXicafcrwnAmtCGxu+87sdsAeX/yRsBIJ2IYoLdB8vMPHIiEGuipOtgKBArQOxZqvZJOjtp/wvhaMUz6EsFMVES1i3fjppAz4wfxf8b2UQqZBOpkE2kQjaRCtlEKmQTqZBNpMI3lRaJbER9sY8AAAAASUVORK5CYII=');
                @media screen and (max-width: 1199px) {
                    width: 49px;
                    height: 36px;
                    background-size: 50px;
                }
            }
            blockquote{
                padding: 91px 96px 160px 52px;
                font-size: 15px;
                font-weight: 400;
                line-height: 22.5px;
                font-weight: normal;
                font-weight: 400;
                @media screen and (max-width: 1199px) {
                    padding: 40px 20px 25px 20px;
                    font-size: 14px;
                }
            }
        }

        .b_image{
            flex-basis: 63%;
            position: relative;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: 1199px) {
                height: 246px;
                flex-basis: unset;
            }
            .play-icon{
                position: absolute;
                display: block;
                bottom: 50%;
                left: 50%;
                transform: translate(-50%, 50%);
                width: 58px;
                height: 58px;
                cursor: pointer;
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAYAAADhu0ooAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASBSURBVHgB3ZvPb9tkGMe/7xsHUQZSEALG0lJH6mCCQ4P4A0ilCbjRTYJrO4n7Mk4Tl6Y3Tu0GEqehJsBth/U6CdT2xI/Lshtai+Jq7bbLhOnG2Gb7ffa8TpslS7IkTezE/kipXdtp/c3zvG+eH68FAqBimikYblYKTBMoK/gFgRRImA0XCrIECQtENgFlBbkB1yhnLMvGgBEYEFqcTLpzLGqWILIApXBI+KbWSaGovOQGi7YwAPoWWpkazyUEzbG42X7EtYMtXSQnudiv4EMLrZwwzYTnrPCN5BAC/QruWajvoi+4ef7PZ4OwYEeEWJy8sVNAj/QkVFtRes4V3s1iuFjKSc70Yl3Z7YWV4xPz0nOvYfgiNaZMOtcqU2/lu31DV0K33xkvSKiVobhqe1JSyGV9b91c3NF1/T9EtIBRpotx+1yhkRB5QAexbYVuv5vOQ2EZUUJhfvLv3VKrUy2FVmdXPfGM1JjsBptn4w9azcYtJyOpnLUIitRwGPr4SsVMNd17k9DquISJyCKy0njpbNPR+l/2A4IKok+TCzdYlGPXaMywnUklks5K/YGaRWNkzRqKxExma2dd79csGiNr1hCcPtb29Y84WnMfHqv/ZTKWbVct6jk5xBP+uhnzreoLTQBziCkCctbfVms9zj+IL777Sl2tQ7zhiuTLWcl55kcImNe//wFHTn+OYSGFN82lVxm4RcdOfoLXvlnm1xKM9DjChpTMSi4ihxa8Hzn9BY6t/Y5Xvy6EK1hSVpIgEyHzyvyXeOPny6G5MwcLKbF9PE0ImLdv7LQ95+7exN3zX+HRH78hSLquAgaFkZ7Amz9dDnz8Dl3oAWMnP0XyxPsIipEQ+uCXq7j92cf4/9erCApDt+6a2nkh8ZDH5b/fLeHRn8GOT/6CsQwWOfBeZCfU3h7sb5dw/8dLCAPiHqyhG7AixDaDFnivdAl0bw/hQbYhIMp+Uy5gtJvePX8O3u4OwoYNWTYU4bocWN+7NTc/fC9kCzbC8fy61GsGWHOg43SYIjWu+/C61AsjBKiMmEKE9VophSBWEVMEoai3vlDlGKWg3XdYKM/b0FtfaNV9VeysSqBixrpj6f1aCOiRLCFmkKMWD/ZrQnVFWy9kQkyot6amIaj3Et6ZuIzVemtqGoRm/uJPgNQFRB2iQr01NU0x0X6dlxvBI7HM5hCQNbl5K/Ps0aZ8VM/AKuGdiqgLc7FazbQ60TLx1i6sQOcQOVSTyx7QtsKQ2dwtal9HVOB7ndy8fbHd6c4LqqaOLXCjsYBRRovcurX4vEu6StBGWmwXIjVdZ6LbU+k8i10YoWU5NhTy7RZQPUuPy1iPmlLJtWEV055CZZ5dT7WbeFpxqNrCEF3ZZle9oNwHF3WO2csb+1hqftRMeHKBc9l5hIBOoMn1zvRixXr6f3ggWME2B+erCVLFia07G+iDwT0OwoI5K8hxRj8nRF8PFNhsvbIQYlU590u9umg7Aqn/6UWHhvHitILMkd9oppTw25PCbLySLNKhpmJhUpQluWVdyBqUuHqeAFK18/p6shpBAAAAAElFTkSuQmCC) center center no-repeat;

                &:hover {
                    animation: box-shadow-hover 2s ease infinite;
                    position: absolute;
                    // transition: all ease 2s;
                    border-radius: 50px;
                }

            }
        }
    }

    .container__photos{
      background-color: #393F45;
      .experts-photos__wrapper{
        padding-top: 100px;
        padding-bottom: 45px;
        @media screen and (max-width: 1199px) {
            margin-left: 0px;
            padding-top: 0px;
            padding-bottom: 25px;
        }

        .nav-btn__wrapper{
            margin-top: 45px;
            .nav-btn{
              margin-left: 163px;
              background: url('../../../assets/img/experts/pointer.png');
              background-size: cover;
              background-repeat: no-repeat;
              display: inline-block;
              position: relative;
              right: 46px;
              font-size: 60px;
              width: 40px;
              height: 40px;
              z-index: 100;
              cursor: pointer;
            }
            .prev-button{
              transform: rotate(180deg);
            }
            .next-button{
                margin-left: 5px;
                position: relative;
                top: 3px;
            }
        }

        .expertPhotoSwiper{
            margin-left: 130px;
            @media screen and (max-width: 1199px) {
              width: 282px;
              height: 160px;
              box-sizing: content-box;
              padding-bottom: 25px;
              padding-top: 20px;
              margin: 0 auto;
            }

            .swiper-slide{
                // flex-shrink: unset;
                min-width: 230px;
            }

            .expert-photo{
              height: 226px;
              width: 170%;
              background-size: cover;
              @media screen and (max-width: 1499px) {
                width: 482px;
                height: 270px;
              }
              @media screen and (max-width: 1199px) {
                width: 282px;
                height: 160px;
              }
            }

            .swiper-pagination{
                padding: 20px 5px;
                bottom: -20px;
                left: -8px;
                display: flex;
                &-bullet{
                    background-color: rgba($color: #FFFFFF, $alpha: .42);
                }
                &-bullet-active{
                    background-color: #E1251B;
                }
            }

        }
      }
    }

    .container__experts{
        header > h2{
            color: #45494E;
            font-size: 64px;
            line-height: 64px;
            font-weight: 300;
            padding: 80px 0px 70px 50px;
            @media screen and (max-width: 1199px) {
              font-size: 24px;
              padding: 11px 0px 00px 10px;
            }
        }

        .swiper-slide{
            // flex-shrink: unset;
            min-width: 230px ;
            // margin-right: 10px;
        }

        .experts-slider{
            margin-left: 130px;
            @media screen and (max-width: 1199px) {
                margin: 0 auto;
            }
            .interviewSwiper{
              @media screen and (max-width: 1199px) {
                width: 283px;
                height: 297px;
                margin-bottom: 22px;
                padding-bottom: 50px;
                box-sizing: content-box;
              }
            }

            &__slide{
                display: flex;
                justify-content: center;
                padding-bottom: 70px;

                .expert_slider{
                    @media screen and (max-width: 1199px) {
                      padding: 0;
                    }
                }

                .expert_name{
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 120%;
                    padding-top: 20px;
                    color: black;
                    @media screen and (max-width: 1199px) {
                      position: relative;
                      bottom: 100px;
                      color: white;
                      padding-left: 15px;
                      padding-top: 10px;
                    }
                    .profession{
                        padding-top: 11px;
                        font-size: 15px;
                        color: #585858;
                        @media screen and (max-width: 1199px) {
                          color: white;
                        }
                    }

                }
            }

            .slider_image{
                width: 217px;
                height: 222px;
                background-size: cover;
                @media screen and (max-width: 1199px) {
                    width: 283px;
                    height: 297px;
                }
            }

            .swiper-pagination{
                padding: 20px 0;
                bottom: 0px;
                @media screen and (max-width: 1199px) {
                    display: flex;
                    left: 0;
                }

                &-bullet-active{
                    background-color: #E1251B;
                }
            }

        }
    }

}
